import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import IconMenu from '../../assets/icons/icon-menu.svg';
import { toggleSideMenu } from '../../store/app/nav/actions';
import { useDispatch } from 'react-redux';
import IconAddBox from '../../components/packing/common/icon-add-box';
import { useHistory } from 'react-router-dom';
import SubscribeSubHeader from './subscribe-sub-header';
import DropDownMenu from '../../assets/icons/dropdown-meu.png';
import { deleteSubscribe, subscribeFilter } from '../../services/report';
import { useSelector } from 'react-redux';
import { getState } from '../../store';
import { toastError, toastSuccess } from '../../helpers/packing/packingHelper';
import { ToastContainer } from 'react-toastify';
function Subscribe() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [subscribeList, setSubscribeList] = useState([]);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const { branch } = getState().user || {};
  const handleMenuToggle = () => {
    dispatch(toggleSideMenu());
  };
  const currentReport = useSelector(
    state => state.reportfilter.current_report || {},
  );
  const filteredSubscribeList =
    subscribeList.filter(
      item =>
        item.subscription_data.report_data.report_id === currentReport.uuid,
    ) || [];
  useEffect(() => {
    const filterSubscribe = async () => {
      try {
        const filterSub = await subscribeFilter({
          req_payload: {
            report_id: [currentReport.uuid],
            branch_id: [branch.branch_id],
            subscription_type: ['REPORT'],
            deleted: [false],
            subscription_name: [],
          },
        });
        setSubscribeList(filterSub.payload);
      } catch (error) {
        console.error('Error in subscribeFilter:', error);
      }
    };
    filterSubscribe();
  }, [branch.branch_id, currentReport.uuid]);
  const handleDelete = async (e, uuid) => {
    e.preventDefault();
    try {
      e.stopPropagation();
      await deleteSubscribe(uuid);
      setSubscribeList(prevList => prevList.filter(item => item.uuid !== uuid));
      toastSuccess('Notification deleted successfully');
    } catch (error) {
      toastError('Notification deleted failed');
      console.error('Error deleting subscription:', error);
    }
  };
  const handleEdit = (e, reason) => {
    e.stopPropagation();
    history.push({
      pathname: '/app/report/newSubscribe',
      state: { reason },
    });
  };
  const handleMenuClick = (e, index) => {
    e.stopPropagation();
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };
  const newSubscription = useCallback(
    e => {
      e.stopPropagation();
      history.push('/app/report/newSubscribe');
    },
    [history],
  );

  return (
    <div className='bg-white min-vh-100 overflow-x-hidden'>
      <div
        className='flex-row header navbar-expand-lg d-flex bg-white p-3 py-4 '
        style={{ zIndex: 10, width: '100%' }}
      >
        <button className='p-0 border-0 bg-white' type='button'>
          <img src={IconMenu} alt='Menu' onClick={handleMenuToggle}></img>
        </button>
        <div className='ms-1'>
          <div className='page-title d-flex align-items-center'>
            <div className='ps-2'>Subscriptions</div>
          </div>
        </div>
      </div>
      <div>
        <SubscribeSubHeader />
      </div>
      <div className='row' style={{ margin: 'auto' }}>
        {filteredSubscribeList.map((reason, index) => (
          <div className='col-md-4 col-sm-6 mt-3' key={reason.uuid}>
            <div
              className='border rounded shadow-sm px-4 py-3 cursor-pointer bg-white position-relative'
              onClick={e => handleEdit(e, reason)}
            >
              <div className='d-flex justify-content-between align-items-center'>
                <div
                  style={{
                    maxWidth: '180px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {reason.subscription_name && (
                    <div className='status-title text-truncate ps-1 bg-white'>
                      {reason.subscription_name}
                    </div>
                  )}
                </div>
                <div className='col-3 my-auto' style={{ textAlign: 'right' }}>
                  <button
                    className='appBtn dropDownListBtn bg-white'
                    style={{ border: 'none' }}
                    onClick={e => handleMenuClick(e, index)}
                  >
                    <img src={DropDownMenu} alt='menu' height='16px' />
                  </button>
                  {openMenuIndex === index && (
                    <div
                      className='menu-options'
                      style={{
                        position: 'absolute',
                        backgroundColor: '#fff',
                        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                        padding: '10px',
                        borderRadius: '4px',
                        bottom: '40px',
                        right: '10px',
                        // zIndex: 100,
                      }}
                    >
                      <button
                        className='delete-option'
                        style={{
                          background: 'none',
                          border: 'none',
                          color: 'black',
                          cursor: 'pointer',
                          padding: '5px 10px',
                        }}
                        onClick={e => {
                          e.stopPropagation();
                          handleDelete(e, reason.uuid);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ToastContainer />
      <div
        className='position-fixed text-center'
        style={{ right: '60px', bottom: '40px' }}
      >
        <div onClick={newSubscription}>
          <IconAddBox />
        </div>
      </div>
    </div>
  );
}

export default Subscribe;

import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import urlParamSanitize from '../../helpers/url-param-sanitizer';
import { resetCutGuideStation } from '../../store/cutguide/cutguide';
import { uniq } from 'lodash';
import {
  resetCgCurrentStation,
  setCgBranchCurrentStation,
  setCgCurrentStation,
} from '../../store/cutguide/cgstationstate';
import { getState } from '../../store';
import { selectCurrentOrg } from '../../helpers/org/orgHelper';
import { getUserStationInstance } from '../../services/items';
import { toastResponseError } from '../../helpers/common/helper';
import { toastError } from '../../helpers/packing/packingHelper';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getWebSocketUrl } from '../../services';
import useWebSocket, { ReadyState } from 'react-use-websocket';
const CUT_GUIDE_HOME = '/app/cutguide/home';

export const getLoginSessionId = login_session_id => {
  return 'cutguide::' + (login_session_id || '');
};
export const CgWebSocket = memo(
  ({ heartBeatMessageCb, onConnectMessageCb, onMessageCb }) => {
    const token = useSelector(state => state.token || '');
    const { org, login_session_id } = useSelector(state => state?.user || {});
    const [socketUrl, setSocketUrl] = useState(
      getWebSocketUrl({
        token: token,
        orgId: org?.uuid,
        login_session_id: getLoginSessionId(login_session_id),
      }),
    );
    const { sendMessage, lastJsonMessage, readyState } = useWebSocket(
      socketUrl,
      {
        heartbeat: {
          message: heartBeatMessageCb
            ? heartBeatMessageCb()
            : 'CONNECTION_CHECK_WITH_RESP',
          interval: 30000,
        },
        shouldReconnect: () => true,
        onOpen: e => {},
        onMessage: e => {},
        onError: e => {},
        onClose: e => {},
      },
      true,
    );
    useEffect(() => {
      if (readyState === ReadyState.OPEN && login_session_id) {
        sendMessage(
          onConnectMessageCb
            ? onConnectMessageCb()
            : 'CONNECTION_CHECK_WITH_RESP',
        );
      }
    }, [readyState, sendMessage, onConnectMessageCb, login_session_id]);
    useEffect(() => {
      if (onMessageCb) {
        onMessageCb(lastJsonMessage);
      }
    }, [lastJsonMessage, onMessageCb]);
    useEffect(() => {
      if (token && org && login_session_id) {
        setSocketUrl(
          getWebSocketUrl({
            token,
            orgId: org.uuid,
            login_session_id: getLoginSessionId(login_session_id),
          }),
        );
      }
    }, [token, org, login_session_id]);

    return <Fragment></Fragment>;
  },
);

/* eslint-disable sonarjs/cognitive-complexity */
export const StationSelector = memo(({ history, location }) => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [stations, setStations] = useState([]);
  const { StationId, OrgId } = useParams();
  const uuid = useMemo(() => urlParamSanitize(StationId), [StationId]);
  const orgId = useMemo(() => urlParamSanitize(OrgId), [OrgId]);
  const [previousStation, setPreviousStation] = useState(null);
  const currentStation = useSelector(
    state => state.cgstationstate.current_station,
  );

  useEffect(() => {
    if (!previousStation && !currentStation) {
      return;
    }
    if (!previousStation) {
      setPreviousStation(currentStation);
      return;
    }
    if (!currentStation && previousStation) {
      dispatch(resetCutGuideStation());
      setPreviousStation(null);
      return;
    }
    if (currentStation.uuid !== previousStation.uuid) {
      dispatch(resetCutGuideStation());
      setPreviousStation(currentStation);
      //syncScannedRecords(true, dispatch);
      //syncOrderItems(dispatch);
    }
  }, [dispatch, previousStation, currentStation, setPreviousStation]);
  const branch = useSelector(state => state.user.branch);
  const org = useSelector(state => state.user.org);
  const getUrl = useCallback(
    ({ station }) => {
      return CUT_GUIDE_HOME + '/' + org.uuid + '/' + station
        ? station.uuid
        : currentStation.uuid;
      //console.log(url);
    },
    [org, currentStation],
  );
  const onDropdownSelect = useCallback(
    station => {
      if (!station) return;
      const operations = station.operations
        ? station.operations.map(val => val.operation.operation_code)
        : [];
      const parentOperations = station.operations
        ? uniq(
            station.operations
              .map(val => val.operation.parent_operation_code)
              .filter(val => val && val.length > 0),
          )
        : [];
      const _station = {
        uuid: station.uuid,
        branch_id: station.branch_id,
        instance_name: station.instance_name,
        station_group: station.station_group,
        station_code: station.station.station_code,
        station_name: station.station.name,
        operations: operations,
        parent_operations: parentOperations,
        allow_scan_uploads: station.station.allow_scan_uploads === true,
        allow_box_scan: station.station.allow_box_scan === true,
        auto_operation: station.station.auto_operation === true,
      };
      dispatch(setCgCurrentStation(_station));
      history.push(CUT_GUIDE_HOME + '/' + org.uuid + '/' + station.uuid);
      return _station;
    },
    [dispatch, org, history],
  );
  /* eslint-disable sonarjs/cognitive-complexity */
  useEffect(() => {
    const fn = async () => {
      const CUT_GUIDE_ORG_HOME = CUT_GUIDE_HOME + '/' + org.uuid;
      //const _currentStation = currentStation || { branch_id: '' };
      if (location && location.pathname === '/app/cutguide') {
        history.replace(CUT_GUIDE_ORG_HOME);
        return;
      }
      if (location && location.pathname === CUT_GUIDE_HOME) {
        history.replace(CUT_GUIDE_ORG_HOME);
        return;
      }
      if (location && !location.pathname.startsWith(CUT_GUIDE_HOME)) {
        return;
      }
      if (!org || !org.uuid) {
        return;
      }
      if (!orgId || orgId === '') {
        history.replace(CUT_GUIDE_ORG_HOME);
        return;
      }
      if (org.uuid !== orgId) {
        const _org = (getState().user.orgs || []).find(
          val => val.uuid === orgId,
        );
        if (!_org) {
          history.replace(CUT_GUIDE_ORG_HOME + '/' + uuid);
          return;
        }
        if (_org.uuid !== orgId) {
          return;
        }
        dispatch(selectCurrentOrg(_org));
        return;
      }
      if (!branch || branch === null) {
        return;
      }
      if (currentStation && !currentStation.branch_id) {
        dispatch(setCgBranchCurrentStation());
        return;
      }
      if (
        currentStation &&
        (branch.branch_id !== currentStation.branch_id ||
          (currentStation.station_code &&
            currentStation.station_code !== 'CUTTING'))
      ) {
        /*if (getOrgBranch(currentStation.branch_id)) {
          dispatch(selectCurrentBranch(currentStation.branch_id));
        }*/
        dispatch(resetCgCurrentStation());
        return;
      }
      if (!branch) {
        return;
      }
      if (!currentStation || !currentStation.uuid) {
        if (uuid !== null && uuid !== '' && branch && branch.branch_id) {
          debugger;
          const getStationResp = await getUserStationInstance();
          if (getStationResp.status !== 200) {
            toastResponseError(
              getStationResp,
              'Unable to Get the Station Detail',
            );
            return;
          }
          const cuttingStations = getStationResp.payload.filter(
            val => val.station && val.station.station_code === 'CUTTING',
          );
          if (cuttingStations.length === 0) {
            toastError('Station Not Found');
            return;
          }
          onDropdownSelect(cuttingStations[0]);
          return;
        }
        return;
      }
      //Reset if Branch Changes
      if (branch.branch_id !== currentStation.branch_id) {
        dispatch(setCgBranchCurrentStation());
        return;
      }
      if (!currentStation || !currentStation.uuid) {
        return;
      }
      if (branch.branch_id !== currentStation.branch_id) {
        return;
      }
      if (uuid !== currentStation.uuid) {
        history.replace(
          getUrl({
            station: currentStation,
          }),
        );
      }
    };
    fn();
    //console.log(path);
  }, [
    uuid,
    orgId,
    org,
    history,
    location,
    currentStation,
    branch,
    dispatch,
    onDropdownSelect,
    getUrl,
  ]);
  /* eslint-enable sonarjs/cognitive-complexity */
  const getStationResp = useCallback(async branch => {
    if (!branch || !branch.branch_id) {
      new Promise(resolve => {
        resolve({
          status: 200,
          payload: [],
        });
      });
    }
    const resp = await getUserStationInstance(
      {
        branch_id: branch ? [branch.branch_id] : [],
        station_code: ['CUTTING'],
        not_station_code: ['packing'],
        deleted: [false],
      },
      0,
      100,
    );
    if (resp.status !== 200) {
      return resp;
    }
    resp.payload = resp.payload.filter(
      val => val?.station?.station_code === 'CUTTING',
    );
    resp.payload.sort((a, b) => {
      return a.instance_name.localeCompare(b.instance_name);
    });
    return resp;
  }, []);
  const onDropdownToggle = useCallback(
    async status => {
      setDropdownOpen(status);
    },
    [setDropdownOpen],
  );
  useEffect(() => {
    const fn = async () => {
      const stationResp = await getStationResp(branch);
      if (stationResp.status === 200) {
        setStations(stationResp.payload);
      }
    };
    fn();
  }, [getStationResp, setStations, branch]);

  /* eslint-disable sonarjs/no-identical-functions */
  const CustomToggle = React.forwardRef(
    (
      { children, onClick },
      ref, //eslint-disable-line
    ) => (
      <div //eslint-disable-line
        className={`title-sm cursor-pointer page-size-display ${
          isDropdownOpen ? 'active' : ''
        }`}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ),
  );

  return (
    <Fragment>
      <div className='d-flex'>
        <div className='d-flex'>
          <Dropdown
            className='page-size-selector'
            onToggle={onDropdownToggle}
            flip={'false'}
            drop={'down'}
          >
            <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
              <div
                className={`btn-label text-primary print-text btn-primary-tapped text-button-padded`}
              >
                <div className={'text-nowrap select-printer-text'}>
                  {currentStation
                    ? currentStation.instance_name
                    : 'Select a Station'}
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu
              className='overflow-auto  dropdown'
              align={{ sm: 'right' }}
            >
              {stations.map(station => (
                <Dropdown.Item
                  onClick={() => onDropdownSelect(station)}
                  disabled={false}
                  key={station.uuid}
                  eventKey={station.uuid}
                  className={`printer-select-box ${
                    currentStation && currentStation.uuid === station.uuid
                      ? 'selected'
                      : ''
                  }`}
                >
                  {station.instance_name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Fragment>
  );
});
/* eslint-enable sonarjs/cognitive-complexity */

StationSelector.prototype = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export const CutGuideWoCardStat = ({
  statCount,
  totalCount,
  label,
  countClassName,
}) => {
  return (
    <Fragment>
      <div className={`rubik-font pe-2 `}>
        <div className='d-flex align-items-start pe-2'>
          <div
            className={`pe-1 ${countClassName}`}
            style={{ fontSize: '20px' }}
          >
            {statCount}
          </div>
          <div style={{ fontSize: '12px', lineHeight: '16px' }}>/</div>
          <div style={{ fontSize: '12px', lineHeight: '16px' }}>
            {totalCount}
          </div>
        </div>
        <div style={{ fontSize: '12px' }}>{label}</div>
      </div>
    </Fragment>
  );
};

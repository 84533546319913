import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';
import { subMonths, startOfDay, endOfDay } from 'date-fns';
import moment from 'moment';

export const OrderTimeFilterOptions = {
  custom: {
    text: 'Custom',
    from: startOfDay(new Date()).toISOString(),
    to: endOfDay(new Date()).toISOString(),
    key: 'custom',
  },
  today: {
    text: 'Today',
    from: startOfDay(new Date()).toISOString(),
    to: endOfDay(new Date()).toISOString(),
    key: 'today',
  },
  yesterday: {
    text: 'Yesterday',
    from: moment().startOf('day').add(-1, 'day').toISOString(),
    to: moment().endOf('day').add(-1, 'day').toISOString(),
    key: 'yesterday',
  },
  this_week: {
    text: 'This week',
    from: moment().startOf('week').toDate().toISOString(),
    to: moment().endOf('week').toDate().toISOString(),
    key: 'this_week',
  },
  this_month: {
    text: 'This month',
    from: moment().startOf('month').toDate().toISOString(),
    to: moment().endOf('day').toDate().toISOString(),
    key: 'this_month',
  },
  last_month: {
    text: 'Last month',
    from: subMonths(moment().startOf('month').toDate(), 1).toISOString(),
    to: moment().startOf('month').toDate().toISOString(),
    key: 'last_month',
  },
  last_two_months: {
    text: 'Last 2 months',
    from: subMonths(moment().startOf('month').toDate(), 1).toISOString(),
    to: moment().endOf('day').toDate().toISOString(),
    key: 'last_two_months',
  },
  last_six_months: {
    text: 'Last 6 months',
    from: subMonths(moment().startOf('month').toDate(), 5).toISOString(),
    to: moment().endOf('day').toDate().toISOString(),
    key: 'last_six_months',
  },
  this_year: {
    text: 'This Year',
    from: moment(new Date(0)).startOf('year').toDate().toISOString(),
    to: moment().endOf('day').toDate().toISOString(),
    key: 'this_year',
  },
  last_year: {
    text: 'Last Year',
    from: moment(new Date(0))
      .startOf('year')
      .add(-1, 'year')
      .toDate()
      .toISOString(),
    to: moment(new Date(0)).startOf('year').toDate().toISOString(),
    key: 'last_year',
  },
  last_2_year: {
    text: 'Last 2 Years',
    from: moment(new Date(0))
      .startOf('year')
      .add(-1, 'year')
      .toDate()
      .toISOString(),
    to: moment().endOf('day').toDate().toISOString(),
    key: 'last_2_year',
  },
  begining: {
    text: 'All',
    from: moment(new Date(0)).startOf('month').toDate().toISOString(),
    to: moment().endOf('day').toDate().toISOString(),
    key: 'begining',
  },
};

export const getOrderDefaultFilter = state => {
  const dateRange = state.orderstate.order_filter_date_range;
  const filterParams = {
    from_date: moment().startOf('month').toDate().toISOString(),
    to_date: moment().endOf('day').toDate().toISOString(),
    pre_defined_time: 'this_month',
    page: 0,
    size: 50,
  };
  if (OrderTimeFilterOptions[`${dateRange}`]) {
    filterParams.from_date = OrderTimeFilterOptions[`${dateRange}`].from;
    filterParams.to_date = OrderTimeFilterOptions[`${dateRange}`].to;
    filterParams.pre_defined_time = OrderTimeFilterOptions[`${dateRange}`].key;
  }
  return filterParams;
};

export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}
const getActionName = (type, verb) => actionTypeName('ORDER_STATE', type, verb);

export const TYPES = {
  ORDER_FILTER_DATE_RANGE: 'ORDER_FILTER_DATE_RANGE',
  ORDER_PROGRESS_GROUP: 'ORDER_PROGRESS_GROUP',
  ORDER_PROGRESS_ITEM_TYPE: 'ORDER_PROGRESS_ITEM_TYPE',
  OP_SHIPMENT_DATE_SORTING: 'OP_SHIPMENT_DATE_SORTING',
  PRODUCTION_SUMMARY_GROUP: 'PRODUCTION_SUMMARY_GROUP',
  PRODUCTION_SUMMARY_GROUP2: 'PRODUCTION_SUMMARY_GROUP2',
  PS_FILTER_DATE_RANGE: 'PS_FILTER_DATE_RANGE',
  PS_OPERATION: 'PS_OPERATION',
  PS_ITEM_TYPE: 'PS_ITEM_TYPE',
  PS_SHOW_CHART: 'PS_SHOW_CHART',
  PACKING_SUMMARY_TEMPLATE_ID: 'PACKING_SUMMARY_TEMPLATE_ID',
  PS_UNIT_TYPE: 'PS_UNIT_TYPE',
};

export const setOrderFilterDateRange = createAction(
  getActionName('ORDER_FILTER_DATE_RANGE', 'SET'),
);

export const setOrderFilterCustomDateRange = createAction(
  getActionName('ORDER_FILTER_CUSTOM_DATE_RANGE', 'SET'),
);
export const setOrderProgressGroup = createAction(
  getActionName(TYPES.ORDER_PROGRESS_GROUP, 'SET'),
);
export const setOrderProgressGroup2 = createAction(
  getActionName(TYPES.ORDER_PROGRESS_GROUP, 'SET_2'),
);
export const setOrderProgressGroup3 = createAction(
  getActionName(TYPES.ORDER_PROGRESS_GROUP, 'SET_3'),
);

export const setOrderProgressCompleted = createAction(
  getActionName(TYPES.ORDER_PROGRESS_GROUP, 'COMPLETED'),
);
export const setOrderProgressItemType = createAction(
  getActionName(TYPES.ORDER_PROGRESS_ITEM_TYPE, 'SET'),
);
export const setOpShipmentDateSort = createAction(
  getActionName(TYPES.OP_SHIPMENT_DATE_SORTING, 'SET'),
);
export const setProductionSummaryGroup = createAction(
  getActionName(TYPES.PRODUCTION_SUMMARY_GROUP, 'SET'),
);
export const setProductionSummaryGroup2 = createAction(
  getActionName(TYPES.PRODUCTION_SUMMARY_GROUP2, 'SET'),
);

export const setPsCustomDateRange = createAction(
  getActionName(TYPES.PS_FILTER_DATE_RANGE, 'SET'),
);
export const setPsOperationCode = createAction(
  getActionName(TYPES.PS_OPERATION, 'SET'),
);
export const setPsItemType = createAction(
  getActionName(TYPES.PS_ITEM_TYPE, 'SET'),
);
export const setPsShowChart = createAction(
  getActionName(TYPES.PS_SHOW_CHART, 'SET'),
);
export const setPackingSummaryTemplateId = createAction(
  getActionName(TYPES.PACKING_SUMMARY_TEMPLATE_ID, 'SET'),
);
export const setPsUnitType = createAction(
  getActionName(TYPES.PS_UNIT_TYPE, 'SET'),
);

export const DEFAULT = {
  order_filter_date_range: 'this_month',
  order_filter_custom_date_range: {
    from: null,
    to: null,
  },
  order_progress_completed: false,
  order_progress_group: 'PARENT_ORDER',
  order_progress_item_type: 'ALL',
  op_shipment_date_sorting: false,
  packing_summary_template_id: null,
  ps_item_type: 'ALL',
  ps_unit_type: 'METER',
  ps_show_chart: true,
  production_summary_group: 'PARENT_ORDER',
  production_summary_group2: 'NONE',
  production_summary_group3: 'NONE',
  ps_filter_custom_date_range: {
    preset: 'today',
    from: null,
    to: null,
  },
  ps_operation_code: null,
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(setOrderFilterDateRange, (state, { payload }) => {
    return Update(state, {
      order_filter_date_range: { $set: payload ? payload : 'this_week' },
    });
  });
  builder.addCase(setOrderFilterCustomDateRange, (state, { payload }) => {
    return Update(state, {
      order_filter_custom_date_range: {
        $set: payload ? payload : { from: null, to: null },
      },
    });
  });
  builder.addCase(setOrderProgressGroup, (state, { payload }) => {
    return Update(state, {
      order_progress_group: {
        $set: payload ? payload : 'PARENT_ORDER',
      },
      order_progress_group2: {
        $set: 'NONE',
      },
    });
  });
  builder.addCase(setOrderProgressGroup2, (state, { payload }) => {
    return Update(state, {
      order_progress_group2: {
        $set: payload ? payload : 'NONE',
      },
    });
  });
  builder.addCase(setOrderProgressGroup3, (state, { payload }) => {
    return Update(state, {
      order_progress_group3: {
        $set: payload ? payload : 'NONE',
      },
    });
  });
  builder.addCase(setOrderProgressCompleted, (state, { payload }) => {
    return Update(state, {
      order_progress_completed: {
        $set: payload === true,
      },
    });
  });
  builder.addCase(setOrderProgressItemType, (state, { payload }) => {
    return Update(state, {
      order_progress_item_type: {
        $set: payload ? payload : 'ALL',
      },
    });
  });
  builder.addCase(setOpShipmentDateSort, (state, { payload }) => {
    return Update(state, {
      op_shipment_date_sorting: {
        $set: payload,
      },
    });
  });
  builder.addCase(setProductionSummaryGroup, (state, { payload }) => {
    const group = payload ? payload : 'PARENT_ORDER';
    return Update(state, {
      production_summary_group: {
        $set: group,
      },
    });
  });
  builder.addCase(setProductionSummaryGroup2, (state, { payload }) => {
    const group = payload;
    return Update(state, {
      production_summary_group2: {
        $set: group,
      },
    });
  });

  builder.addCase(setPsCustomDateRange, (state, { payload }) => {
    return Update(state, {
      ps_filter_custom_date_range: {
        $set: payload ? payload : { from: null, to: null, present: 'today' },
      },
    });
  });
  builder.addCase(setPsOperationCode, (state, { payload }) => {
    return Update(state, {
      ps_operation_code: {
        $set: payload,
      },
    });
  });
  builder.addCase(setPsItemType, (state, { payload }) => {
    return Update(state, {
      ps_item_type: {
        $set: payload ? payload : 'ALL',
      },
    });
  });
  builder.addCase(setPsUnitType, (state, { payload }) => {
    return Update(state, {
      ps_unit_type: {
        $set: payload ? payload : 'Meter',
      },
    });
  });
  builder.addCase(setPsShowChart, (state, { payload }) => {
    return Update(state, {
      ps_show_chart: {
        $set: payload === true,
      },
    });
  });
  builder.addCase(setPackingSummaryTemplateId, (state, { payload }) => {
    return Update(state, {
      packing_summary_template_id: {
        $set: payload ? payload : null,
      },
    });
  });
});

export default reducer;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PageAction from '../../common/page-action';

const Status = ({
  label,
  label2,
  completed,
  total,
  color,
  onClick,
  background,
  statusActions,
}) => {
  const width = total > 0 ? 100 / (total / completed) : 0;

  let backgroundColor = background;

  if (color && width < 100) {
    backgroundColor = '#FFB300';
  }
  if (completed >= total && completed > 0) {
    //backgroundColor = undefined;
  }

  return (
    <Fragment>
      <div
        className='d-flex justify-content-between align-items-center'
        onClick={e => (onClick ? onClick(e) : null)}
      >
        <div style={{ maxWidth: '180px' }}>
          <div className='status-title text-truncate ps-1'>{label}</div>
          {label2 && (
            <div className='status-title text-truncate ps-1'>{label2}</div>
          )}
        </div>
        <div className='status-count fs-24 d-flex justify-content-end w-100'>
          <b className='bold'>{completed}</b>/{total}
        </div>
        {statusActions && statusActions.length > 0 && (
          <Fragment>
            <PageAction actions={statusActions} />
          </Fragment>
        )}
      </div>
      <div
        className='status-progress position-relative overflow-hidden'
        onClick={e => (onClick ? onClick(e) : null)}
      >
        <div
          className={`status-progress-completed ${
            onClick ? 'cursor-pointer' : ''
          }`}
          style={{
            width: `${width}%`,
            backgroundColor,
          }}
        ></div>
      </div>
    </Fragment>
  );
};

Status.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  completed: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.bool,
  onClick: PropTypes.func,
  background: PropTypes.string,
};

export default Status;

import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { ProgressOperationItems } from './progress-operation-items';
import { TrackTraceItemFieldSelectedIcon } from '../../../assets/icons/icon-tracktrace';
import {
  getProgressHeaderName,
  getProgressRowGroupVal,
  getProgressRowRefreshGroup,
  getRecentOrdersList,
  refreshOrderProgressPipeline,
} from '../../../helpers/order/progressHelper';
import { RefreshIcon } from '../../../assets/icons/nav-icons';
import {
  toastError,
  toastSuccess,
} from '../../../helpers/packing/packingHelper';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@naadi/framework';
import { getOrgConfig } from '../../../services/org';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { toastResponseError } from '../../../helpers/common/helper';
import { updateOrderService } from '../../../services/order';
import { Modal } from 'react-bootstrap';
import { setOpShipmentDateSort } from '../../../store/order/orderstate/orderstate';
const ProgressHeader = ({
  group,
  group2,
  group3,
  opSequence,
  operationWidth,
  referenceWidth,
}) => {
  const dispatch = useDispatch();
  const [header1, setHeader1] = useState('Reference');
  const [header2, setHeader2] = useState('Reference2');
  const [header3, setHeader3] = useState('Reference3');
  const isShortedDate = useSelector(
    state => state.orderstate.op_shipment_date_sorting,
  );
  useEffect(() => {
    setHeader1(getProgressHeaderName(group, 'Reference'));
  }, [group, setHeader1]);
  useEffect(() => {
    setHeader2(getProgressHeaderName(group2, 'Reference 2'));
  }, [group2, setHeader2]);
  useEffect(() => {
    setHeader3(getProgressHeaderName(group3, 'Reference 3'));
  }, [group3, setHeader3]);

  return (
    <Fragment>
      <div
        className='rubik-font d-flex bg-white pt-2 pb-3'
        style={{
          zIndex: '1',
          position: 'sticky',
          top: '0px',
          borderBottom: '1px solid #027aff',
          fontWeight: 'bold',
        }}
      >
        <div
          className='bg-white'
          style={{
            width: referenceWidth + 'px',
            position: 'sticky',
            left: '0px',
          }}
        >
          {header1}
        </div>
        {group2 !== 'NONE' && group2 !== '' && (
          <div
            className='bg-white'
            style={{
              width: referenceWidth + 'px',
            }}
          >
            {header2}
          </div>
        )}
        {group3 !== 'NONE' && group3 !== '' && (
          <div
            className='bg-white'
            style={{
              width: referenceWidth + 'px',
            }}
          >
            {header3}
          </div>
        )}
        <div
          className=' d-flex justify-content-end'
          style={{
            width: operationWidth + 'px',
          }}
        >
          Completion %
        </div>
        {opSequence.map(operation =>
          operation === 'Shipment_Date' ? (
            <div
              key={operation}
              style={{ width: operationWidth + 'px', paddingLeft: '20px' }}
              className='d-flex justify-content-end'
              onClick={() => {
                dispatch(setOpShipmentDateSort(!isShortedDate));
              }}
            >
              {operation}
              <span className='ms-2 pt-0 float-right'>
                <FontAwesomeIcon
                  icon={isShortedDate ? faCaretUp : faCaretDown}
                />
              </span>
            </div>
          ) : (
            <div
              key={operation}
              style={{ width: operationWidth + 'px', paddingLeft: '20px' }}
              className='d-flex justify-content-end'
            >
              {operation}
            </div>
          ),
        )}

        <div
          className=' d-flex justify-content-end'
          style={{
            width: '60px',
          }}
        ></div>
      </div>
    </Fragment>
  );
};
const ProgressLine = ({
  group,
  group2,
  group3,
  order_progress_completed,
  onDetail,
  opSequence,
  orderStat,
  setPsList,
  allOrders,
  operationWidth,
  referenceWidth,
  setOperationItems,
  onRefresh,
  itemTypes,
}) => {
  const dispatch = useDispatch();
  const [reference, setReference] = useState('');
  const [reference2, setReference2] = useState('');
  const [reference3, setReference3] = useState('');
  const [refreshGroup, setRefreshGroup] = useState('');
  const [prioritizeModal, setPrioritizeModal] = useState({
    modal: false,
    order: {},
  });
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [isChildChecked, setIsChildChecked] = useState(false);
  const [childOrders, setChildOrders] = useState([]);
  const [, setRefreshOrder] = useState({});
  const modalLabelName =
    prioritizeModal?.order?.pinned === true ? 'De-Prioritize' : 'Prioritize';
  const updatePinnedOrder = useCallback(
    async order => {
      dispatch(actions.busy.add('PRIORITIZE_ORDERS'));

      const updateOrder = async (orderUuid, pinned) => {
        try {
          const resp = await updateOrderService(orderUuid, { pinned });
          if (resp.status !== 200) {
            toastResponseError(resp, 'Unable to update the pinned state');
            return null;
          }
          return resp.payload;
        } catch (error) {
          toastError('Error updating order');
          return null;
        }
      };
      if (isChildChecked) {
        const targetOrders = order.order.child_orders || childOrders;

        const updatedOrders = await Promise.all(
          targetOrders.map(co =>
            updateOrder(
              co.uuid || co.order.uuid,
              modalLabelName === 'Prioritize',
            ),
          ),
        );

        if (updatedOrders.some(order => order !== null)) {
          setPsList(prev =>
            prev.map(order => {
              const updatedOrder = updatedOrders.find(
                v => v.uuid === order.order.uuid,
              );
              if (updatedOrder) {
                return {
                  ...order,
                  pinned: updatedOrder.pinned,
                };
              }
              return order;
            }),
          );
          setRefreshOrder(updatedOrders);
        }

        setIsChildChecked(false);
      } else if (!isChildChecked) {
        const updatedOrder = await updateOrder(order.order.uuid, !order.pinned);
        if (updatedOrder !== null) {
          orderStat.pinned = updatedOrder.pinned;
          setRefreshOrder(updatedOrder);
        }
      }
      dispatch(actions.busy.remove('PRIORITIZE_ORDERS'));
    },
    [
      childOrders,
      dispatch,
      isChildChecked,
      modalLabelName,
      orderStat,
      setPsList,
    ],
  );

  const modalHandler = useCallback(
    async order => {
      let childOrders = [];
      if (order.order?.child_orders?.length > 1) {
        childOrders = order.order.child_orders;
      } else {
        childOrders = allOrders?.payload?.filter(co => {
          const parentOrder = order?.order?.parent_order;
          if (!parentOrder || Object.keys(parentOrder).length === 0) {
            return (
              co?.order?.parent_order?.uuid === order?.order?.uuid ||
              co?.order?.uuid === order?.order?.uuid
            );
          }
          return false;
        });
      }
      setChildOrders(childOrders);

      if (childOrders.length > 1) {
        setPrioritizeModal({ modal: true, order: order });
      } else {
        updatePinnedOrder(order);
      }
    },
    [allOrders, updatePinnedOrder],
  );

  useEffect(() => {
    setReference(getProgressRowGroupVal(orderStat.group, orderStat));
    setReference2(getProgressRowGroupVal(orderStat.group2, orderStat));
    setReference3(getProgressRowGroupVal(orderStat.group3, orderStat));
    setRefreshGroup(
      getProgressRowRefreshGroup(
        orderStat.group,
        orderStat.group2,
        orderStat.group3,
      ),
    );
  }, [orderStat, setReference, setReference2, setReference3, setRefreshGroup]);
  return (
    <Fragment>
      <div style={{ width: '100%' }} className='d-flex bg-white pt-1 pb-1'>
        <div
          className='bg-white rubik-font bold-link cursor-pointer d-flex align-items-center'
          style={{
            width: referenceWidth + 'px',
            position: 'sticky',
            left: '0px',
            paddingLeft: orderStat.level * 24 + 'px',
          }}
          onClick={() => {
            if (onDetail) {
              onDetail(orderStat);
            }
          }}
        >
          <div className='p-0'>
            <TrackTraceItemFieldSelectedIcon
              selected={orderStat.pinned}
              onClick={event => {
                event.stopPropagation();
                modalHandler(orderStat);
              }}
            />
          </div>
          <div className='ps-1'>{reference}</div>
        </div>
        {group2 !== '' && group2 !== 'NONE' && (
          <div
            className='rubik-font d-flex align-items-center'
            style={{
              width: referenceWidth + 'px',
            }}
          >
            <div className='ps-1'>{reference2}</div>
          </div>
        )}
        {group3 !== '' && group3 !== 'NONE' && (
          <div
            className='rubik-font d-flex align-items-center'
            style={{
              width: referenceWidth + 'px',
            }}
          >
            <div className='ps-1'>{reference3}</div>
          </div>
        )}
        <div
          className='rubik-font d-flex align-items-center  justify-content-end'
          style={{
            width: operationWidth + 'px',
          }}
        >
          <div
            className={`ps-1 ${
              orderStat.completion >= 100
                ? 'success'
                : orderStat.completion > 0
                ? 'warning'
                : ''
            }`}
          >
            {orderStat.completion}
          </div>
        </div>

        {opSequence.map(operation => {
          const stat = orderStat.operation_stats[`${operation}`] || {};
          const totalPipeline = stat.total_pipeline || 0;
          const totalPending = stat.total_pending_qty || 0;
          const totalCompleted = totalPipeline - totalPending;
          let displayClass = '';
          if (totalCompleted >= totalPipeline && totalCompleted > 0) {
            displayClass = 'success';
          } else if (totalCompleted > 0) {
            displayClass = 'warning';
          }
          return (
            <div
              key={operation + '-' + orderStat.key}
              style={{
                width:
                  operation === 'Shipment_Date'
                    ? '200px'
                    : `${operationWidth}px`,
              }}
              className={`d-flex align-items-center justify-content-end ${displayClass}`}
              onClick={() => {
                setOperationItems({
                  show: true,
                  operation: operation,
                  order_stat: orderStat,
                });
              }}
            >
              {operation === 'Shipment_Date' ? (
                orderStat?.order?.delivery_date ? (
                  moment(orderStat.order.delivery_date).format('DD-MM-YYYY')
                ) : (
                  ''
                )
              ) : (
                <>
                  {Math.round(
                    (order_progress_completed ? totalCompleted : totalPending) *
                      10,
                  ) / 10.0}{' '}
                  / {Math.round(totalPipeline * 10) / 10.0}
                </>
              )}
            </div>
          );
        })}

        <div
          className='rubik-font d-flex align-items-center  justify-content-end'
          style={{
            width: '60px',
          }}
        >
          {refreshGroup && refreshGroup !== '' && (
            <div
              className='cursor-pointer'
              onClick={async () => {
                try {
                  dispatch(actions.busy.add('REFRESH_PIPELINE'));
                  const stat = await refreshOrderProgressPipeline(orderStat);
                  if (stat.success) {
                    toastSuccess('Order Stat Refreshed');
                    onRefresh();
                  } else {
                    toastError(
                      stat.err && stat.err.err
                        ? stat.err.err
                        : stat.err
                        ? stat.err
                        : 'Unable to refresh',
                    );
                  }
                } finally {
                  dispatch(actions.busy.remove('REFRESH_PIPELINE'));
                }
              }}
            >
              <RefreshIcon />
            </div>
          )}
        </div>
      </div>
      {prioritizeModal.modal && (
        <Modal
          centered={true}
          show={prioritizeModal.modal}
          onHide={() => setPrioritizeModal(val => ({ ...val, modal: false }))}
        >
          <Modal.Header
            className='px-16 pb-0'
            style={{ borderBottom: '0px' }}
            closeButton
          >
            <div className='modal-title'>
              {modalLabelName} {' Order'}
            </div>
          </Modal.Header>
          <Modal.Body className='py-16'>
            <div>
              <div className='fs-32'>
                <div className='d-flex align-items-center py-32'>
                  <input
                    type='checkbox'
                    className='main-box-checkbox cursor-pointer pe-3'
                    id='parentCheckbox'
                    checked={isParentChecked}
                    onChange={() => setIsParentChecked(!isParentChecked)}
                  />
                  <label
                    className='form-check-label ps-3'
                    style={{ fontFamily: 'Roboto' }}
                    htmlFor='parentCheckbox'
                  >
                    {modalLabelName} only {prioritizeModal.order.reference}
                  </label>
                </div>

                <div className='d-flex align-items-center'>
                  <input
                    type='checkbox'
                    className='main-box-checkbox cursor-pointer'
                    id='childCheckbox'
                    checked={isChildChecked}
                    onChange={() => setIsChildChecked(!isChildChecked)}
                  />
                  <label
                    className='form-check-label ps-3'
                    htmlFor='childCheckbox'
                    style={{ fontFamily: 'Roboto' }}
                  >
                    {modalLabelName} {prioritizeModal.order.reference} and all
                    child orders ({childOrders.length - 1})
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <div className='d-flex justify-content-end align-items-center gap-3 px-16 py-4'>
            <div
              onClick={() =>
                setPrioritizeModal(val => ({ ...val, modal: false }))
              }
              className='btn btn-outline-primary'
            >
              Close
            </div>
            <div
              onClick={() => {
                updatePinnedOrder(prioritizeModal.order);
                setPrioritizeModal(val => ({ ...val, modal: false }));
              }}
              className='btn btn-primary'
            >
              Done
            </div>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};
export const ProgressContainer = ({
  group,
  group2,
  group3,
  order_progress_completed,
  progressList,
  operations,
  onDetail,
  onRefresh,
  itemTypes,
}) => {
  const [referenceWidth] = useState(200);
  const [operationWidth] = useState(200);
  const [minWidth, setMinWidth] = useState(referenceWidth);
  const [operationItems, setOperationItems] = useState({ show: false });
  const [opSequence, setOpSequence] = useState([]);
  const [psList, setPsList] = useState();
  const [allOrders, setAllOrders] = useState([]);
  const isShortedDate = useSelector(
    state => state.orderstate.op_shipment_date_sorting,
  );
  useEffect(() => {
    const fetchingAllOrders = async () => {
      const allOrders = await getRecentOrdersList(
        group,
        group2,
        group3,
        itemTypes,
        '',
      );
      setAllOrders(allOrders);
    };
    fetchingAllOrders();
  }, [group, group2, group3, itemTypes]);

  useEffect(() => {
    const opSequence = async () => {
      const orgConfigResp = await getOrgConfig('STATION_SEQUENCE');
      if (orgConfigResp.status === 200) {
        const opSequence = orgConfigResp.payload;
        if (
          opSequence.value &&
          opSequence.value.val &&
          opSequence.value.val.length > 0
        ) {
          setOpSequence(opSequence.value.val);
        }
      }
    };
    opSequence();
  }, []);
  useEffect(() => {
    setMinWidth(referenceWidth + opSequence.length * operationWidth);
  }, [referenceWidth, operationWidth, opSequence]);
  const onShortDate = useCallback(() => {
    const sorted = progressList.sort((a, b) => {
      const dateA = a.order?.delivery_date
        ? moment(a.order.delivery_date)
        : null;
      const dateB = b.order?.delivery_date
        ? moment(b.order.delivery_date)
        : null;
      if (dateA && dateB) {
        return isShortedDate ? dateA - dateB : dateB - dateA;
      }
      if (!dateA) return 1;
      if (!dateB) return -1;
      return 0;
    });
    setPsList(sorted);
  }, [isShortedDate, progressList]);
  useEffect(() => {
    onShortDate();
  }, [onShortDate]);
  return (
    <Fragment>
      <div
        className=''
        style={{
          paddingTop: '0px !important',
          width: '100%',
          position: 'relative',
          height: 'calc(100vh - 200px)',
          overflow: 'scroll',
        }}
      >
        <div
          className='font-weight-bold pt-3 table'
          style={{ minWidth: minWidth }}
        >
          <ProgressHeader
            group={group}
            group2={group2}
            group3={group3}
            opSequence={opSequence}
            referenceWidth={referenceWidth}
            operationWidth={operationWidth}
          />
          {psList &&
            psList.map((orderStat, i) => {
              return (
                <Fragment key={i}>
                  <ProgressLine
                    opSequence={opSequence}
                    order_progress_completed={order_progress_completed}
                    orderStat={orderStat}
                    group={group}
                    group2={group2}
                    group3={group3}
                    setPsList={setPsList}
                    allOrders={allOrders}
                    referenceWidth={referenceWidth}
                    operationWidth={operationWidth}
                    onDetail={onDetail}
                    setOperationItems={setOperationItems}
                    onRefresh={onRefresh}
                    itemTypes={itemTypes}
                  />
                </Fragment>
              );
            })}
        </div>
      </div>
      {operationItems.show && (
        <ProgressOperationItems
          operationItems={operationItems}
          onCloseCb={() => {
            setOperationItems({ show: false });
          }}
          itemTypes={itemTypes}
        />
      )}
    </Fragment>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastError } from '../../helpers/packing/packingHelper';
import {
  getReportTypes,
  getReports,
  getReportConfigByCode,
} from '../../services/report';
import { selectReport } from '../../helpers/report/reportHelper';
import GroupSelect from '../../components/common/groupselect/groupselect';
import { ResponseCodes } from '@naadi/framework';

function SubscribeSubHeader() {
  const dispatch = useDispatch();
  const currentReport = useSelector(
    state => state.reportfilter.current_report || {},
  );
  const [showGroupSelect, setShowGroupSelect] = useState(false);
  // const [reportConfigs, setReportConfigs] = useState([]);
  const [groupSelected, setGroupSelected] = useState(null);
  useEffect(() => {
    if (currentReport) {
      const groupSelect = {
        org_id: currentReport.org_id,
        report_type: currentReport.report_type,
      };
      setGroupSelected(groupSelect);
    }
  }, [currentReport]);
  const reportTypesFetch = useCallback(async () => {
    const response = await getReportTypes();
    if (response.status === ResponseCodes.SUCCESS) {
      return response.payload;
    } else {
      toastError('Unable to Fetch Report Types');
    }
  }, []);
  const getGroupOptions = useCallback(reportTypesFetch, [reportTypesFetch]);
  const fetchReportForGroup = useCallback(async (reportTypes, term) => {
    if (!reportTypes) return [];
    const result = [];
    for (const reportType of reportTypes) {
      const reportResult = await getReports({
        page: 0,
        size: 10,
        req_payload: {
          term,
          report_type: [reportType],
          deleted: [false],
          visible: [true],
        },
      });
      if (reportResult.status === ResponseCodes.SUCCESS) {
        result.push(...reportResult.payload);
      } else {
        toastError('Unable to Fetch Reports');
      }
    }
    return result;
  }, []);

  const onReportSelect = useCallback(
    report => {
      selectReport(report, null, false, true, dispatch);
      setShowGroupSelect(false);
    },
    [dispatch],
  );

  const fetchReportConfigs = useCallback(async () => {
    if (currentReport && currentReport.uuid) {
      await getReportConfigByCode(currentReport.uuid);
      // if (response.status === ResponseCodes.SUCCESS) {
      //   setReportConfigs(response.payload);
      // }
    }
  }, [currentReport]);

  useEffect(() => {
    fetchReportConfigs();
  }, [fetchReportConfigs]);
  return (
    <div className='row px-3 pb-4 pt-0'>
      <div className='col-md-6 col-sm-12 p-0'>
        <div
          className='d-flex align-items-center mx-3'
          style={{
            borderLeft: '4px solid green',
            paddingLeft: '10px',
            height: '64px',
          }}
        >
          <div
            className='d-flex flex-column '
            style={{ cursor: 'pointer', zIndex: 10 }}
            onClick={() => setShowGroupSelect(!showGroupSelect)}
          >
            <span className='mb-3'>
              <span
                style={{
                  fontSize: '16px',
                  marginLeft: '5px',
                  color: '#323232',
                }}
              >
                <GroupSelect
                  props
                  label='Select A Report ...'
                  getGroupOptions={getGroupOptions}
                  onGroupSelect={fetchReportForGroup}
                  groupLabelField='report_type'
                  groupValueField='report_type'
                  labelField='report_name'
                  valueField='uuid'
                  onSelect={onReportSelect}
                  selected={currentReport}
                  groupSelected={groupSelected}
                  className='order-id fs-24 sub-custom-text-blue'
                  search
                />
              </span>
            </span>
            <span
              style={{
                fontSize: '12px',
                color: '#A9A9A9',
              }}
            >
              REPORT
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeSubHeader;

/* eslint-disable sonarjs/cognitive-complexity */
import React, { Fragment, useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '../../../assets/icons';
import { setPsShowChart } from '../../../store/order/orderstate/orderstate';
import { useDispatch, useSelector } from 'react-redux';
import {
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  LabelList,
} from 'recharts';
import moment from 'moment';
const TABLE_ROW_BORDER = 'p-summary-table-row-border';

const convertUoM = (qty, ps_unit_type) => {
  if (ps_unit_type === 'FEET') {
    return (qty * 0.3048).toFixed(2);
  } else {
    return qty;
  }
};
const PsGroupSummaryLine = ({ filterSummery, psContainer }) => {
  const { ps_unit_type } = useSelector(state => state.orderstate);

  return (
    <>
      {filterSummery.map(summary => (
        <Fragment key={summary.key}>
          <div
            className={`d-flex bg-white pt-0 pb-0 flex-column flex-md-row ${TABLE_ROW_BORDER}`}
            style={{
              left: '0px',
            }}
          >
            <div
              className='d-flex flex-column flex-lg-row bg-white'
              style={{ flexBasis: '200px' }}
              // style={{
              //   width: '200px',
              //   minWidth: '200px',
              //   maxWidth: '200px',
              //   textOverflow: 'ellipsis',
              // }}
            >
              <div className='w-100 pt-2 pb-3 text-truncate'>
                <div>{summary.label}</div>
                <div>{convertUoM(summary.qty, ps_unit_type)}</div>
              </div>
            </div>
            <div
              className='d-flex flex-grow-1 col-lg-2  col-md-2  p-summary-table-col-l-border p-summary-table-col-r-border'
              // style={{ width: '940', maxWidth: '940' }}
            >
              {summary?.list.map((itemTypeSummary, itemTypeSummaryIndex) => {
                const itemSummaryBC =
                  itemTypeSummaryIndex < summary?.list.length - 1
                    ? TABLE_ROW_BORDER
                    : '';
                return (
                  <div
                    key={itemTypeSummary.key}
                    className={`w-100 d-flex py-0 justify-content-md-start text-truncate ${itemSummaryBC}`}
                  >
                    <div
                      style={{ minWidth: '120px' }}
                      className='d-flex flex-column'
                    >
                      <div className='pb-2 pt-2 '>
                        <div>{itemTypeSummary.key}</div>
                        <div>
                          {convertUoM(itemTypeSummary.qty, ps_unit_type)}
                        </div>
                      </div>
                    </div>
                    <div
                      className='p-summary-table-col-l-border'
                      style={{ minWidth: '200px' }}
                    >
                      {itemTypeSummary.list.map((groupSummary, gsIndex) => {
                        const gsBC =
                          gsIndex < itemTypeSummary?.list.length - 1
                            ? TABLE_ROW_BORDER
                            : '';
                        return (
                          <div
                            className={`d-flex w-100 py-0  d-flex ${gsBC}`}
                            key={groupSummary.key}
                          >
                            <div
                              style={{ minWidth: '160px' }}
                              className='d-flex justify-content-start '
                            >
                              <div
                                style={{ width: '200px' }}
                                className='pt-4 px-2 py-2'
                              >
                                {groupSummary.key ? groupSummary.key : '-'}
                              </div>
                            </div>
                            <div
                              className='p-summary-table-col-l-border '
                              style={{ width: '320px', maxWidth: '320px' }}
                            >
                              {groupSummary.list.map(
                                (group2Summary, gs2Index) => {
                                  const gs2BC =
                                    gs2Index < groupSummary?.list.length - 1
                                      ? TABLE_ROW_BORDER
                                      : '';
                                  return (
                                    <div
                                      className={`w-100 d-flex col-md-2 col-lg-2 h-100 ${gs2BC}`}
                                      key={group2Summary.key}
                                    >
                                      <div
                                        style={{
                                          minWidth: '120px',
                                        }}
                                        className='d-flex py-0 justify-content-start p-summary-table-col-r-border'
                                      >
                                        <div className='px-2 py-2'>
                                          {group2Summary.key
                                            ? group2Summary.key
                                            : '-'}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          minWidth: '100px',
                                          maxWidth: '100px',
                                        }}
                                        className='d-flex justify-content-center py-2 px-2 col-md-2 col-lg-2'
                                      >
                                        {convertUoM(
                                          group2Summary.qty,
                                          ps_unit_type,
                                        )}
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        minWidth: '160px',
                        maxWidth: '160px',
                      }}
                      className='d-flex p-summary-table-col-l-border
                      p-summary-table-col-r-border justify-content-center py-2 col-lg-2 col-md-2 '
                    >
                      {convertUoM(itemTypeSummary.qty, ps_unit_type)}
                    </div>
                    <div>
                      <div
                        className='bg-white h-100 d-flex '
                        style={{
                          minWidth: '100px',
                          maxWidth: '100px',
                        }}
                      >
                        <div className='w-100 pt-2 pb-3 p-summary-table-col-r-border justify-content-end mx-auto'>
                          <div className='w-100 d-flex justify-content-center'>
                            {convertUoM(summary.qty, ps_unit_type)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Fragment>
      ))}
    </>
  );
};

const PsSummaryTable = ({
  itemType,
  group,
  group2,
  showChart,
  groupSummary,
  consolidationTypes,
  onDetail,
  psContainer,
}) => {
  const [periodInput, setPeriodInput] = useState('');
  const [itemCodeInput, setItemCodeInput] = useState('');
  const [groupInput, setGroupInput] = useState('');
  const [group2Input, setGroup2Input] = useState('');
  const [qtyInput, setQtyInput] = useState('');
  const [totalInput, setTotalInput] = useState('');
  const [filterSummery, setFilterSummery] = useState(groupSummary);
  const [term, setTerm] = useState('');
  const { ps_unit_type } = useSelector(state => state.orderstate);
  useEffect(() => {
    const input =
      periodInput ||
      itemCodeInput ||
      groupInput ||
      group2Input ||
      qtyInput ||
      totalInput;
    if (!input) {
      setFilterSummery(groupSummary);
    }
    const searchTerm = input.toLowerCase();
    if (term === 'PERIOD' || term === 'TOTAL') {
      setFilterSummery(
        groupSummary.filter(itemTypeSummary => {
          const searchKey = itemTypeSummary.key
            ? itemTypeSummary.key.toLowerCase()
            : '';
          const searchQty = itemTypeSummary.qty
            ? itemTypeSummary.qty.toString()
            : '';
          return term === 'TOTAL'
            ? searchQty.toLowerCase().includes(searchTerm.toLowerCase())
            : searchKey.toLowerCase().includes(searchTerm.toLowerCase()) ||
                searchQty.toLowerCase().includes(searchTerm.toLowerCase());
        }),
      );
    } else if (term === 'ITEM-CODE') {
      setFilterSummery(
        groupSummary.filter(summary =>
          summary.list.some(itemTypeSummary => {
            const searchQty = itemTypeSummary.qty
              ? itemTypeSummary.qty.toString()
              : '';
            const searchEdge = itemTypeSummary.edge
              ? itemTypeSummary.edge.toString()
              : '';
            return (
              searchQty
                .trim()
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              searchEdge.trim().toLowerCase().includes(searchTerm.toLowerCase())
            );
          }),
        ),
      );
    } else if (term === group) {
      setFilterSummery(
        groupSummary.filter(summary =>
          summary.list.some(summary =>
            summary.list.some(itemTypeSummary => {
              const searchKey = itemTypeSummary.key ? itemTypeSummary.key : '';

              return searchKey
                .trim()
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            }),
          ),
        ),
      );
    } else if (term === group2) {
      setFilterSummery(
        groupSummary.filter(summary =>
          summary.list.some(summary =>
            summary.list.some(summary =>
              summary.list.some(itemTypeSummary => {
                const searchQty = itemTypeSummary.key
                  ? itemTypeSummary.key.toString()
                  : '';

                return searchQty
                  .trim()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase());
              }),
            ),
          ),
        ),
      );
    } else if (term === 'QTY') {
      setFilterSummery(
        groupSummary.filter(summary =>
          summary.list.some(summary =>
            summary.list.some(itemTypeSummary => {
              const searchQty = itemTypeSummary.qty
                ? itemTypeSummary.qty.toString()
                : '';

              return searchQty
                .trim()
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            }),
          ),
        ),
      );
    }
  }, [
    group,
    group2,
    group2Input,
    groupInput,
    groupSummary,
    itemCodeInput,
    periodInput,
    psContainer,
    qtyInput,
    setFilterSummery,
    term,
    totalInput,
  ]);

  return (
    <Fragment>
      <div
        className='rubik-font d-flex bg-white pt-2 pb-0'
        style={{
          zIndex: '1',
          position: 'sticky',
          top: '0px',
          borderBottom: '1px solid #027aff',
          fontWeight: 'bold',
        }}
      >
        <div
          className='bg-white align-items-center'
          style={{
            width: '200px',
            minWidth: '200px',
            maxWidth: '200px',
            position: 'sticky',
            left: '0px',
          }}
        >
          <span className='ps-2'>Period</span>
          <form onSubmit={e => e.preventDefault()}>
            <input
              type='search'
              className='table-search-input mb-1'
              placeholder={'Search'}
              name={'PERIOD'}
              onChange={e => setPeriodInput(e.target.value)}
              onClick={() => setTerm('PERIOD')}
            />
          </form>
        </div>

        <div
          style={{
            width: '200px',
            maxWidth: '200px',
            textOverflow: 'ellipsis',
          }}
          className='align-items-center justify-content-start overflow-hidden'
        >
          <span className='ps-2'>
            {psContainer === 'RFT' ? 'EDGE-CODE' : 'ITEM-CODE'}
          </span>

          <form onSubmit={e => e.preventDefault()}>
            <input
              type='search'
              className='table-search-input mb-1'
              placeholder={'Search'}
              name={'ITEM-CODE'}
              onChange={e => setItemCodeInput(e.target.value)}
              onClick={() => setTerm('ITEM-CODE')}
            />
          </form>
        </div>

        <div
          style={{ width: '200px', textOverflow: 'ellipsis' }}
          className=' align-items-center justify-content-start overflow-hidden'
        >
          <span className='ps-2'>{group}</span>
          <form onSubmit={e => e.preventDefault()}>
            <input
              type='search'
              className='table-search-input mb-1'
              placeholder={'Search'}
              name={group}
              onChange={e => setGroupInput(e.target.value)}
              onClick={() => setTerm(group)}
            />
          </form>
        </div>

        <div
          style={{
            width: '200px',
            maxWidth: '200px',
            textOverflow: 'ellipsis',
          }}
          className='align-items-center justify-content-start overflow-hidden '
        >
          <span className='ps-2'>{group2}</span>
          <form onSubmit={e => e.preventDefault()}>
            <input
              type='search'
              className='table-search-input mb-1'
              placeholder={'Search'}
              name={group2}
              onChange={e => setGroup2Input(e.target.value)}
              onClick={() => setTerm(group2)}
            />
          </form>
        </div>

        <div
          style={{ minWidth: '120px', maxWidth: '120px' }}
          className='align-items-center justify-content-end'
        >
          <span className='ps-2'>
            QTY{' '}
            {psContainer === 'RFT'
              ? ps_unit_type === 'FEET'
                ? '(ft)'
                : '(m)'
              : ''}
          </span>

          <form onSubmit={e => e.preventDefault()}>
            <input
              type='search'
              className='table-search-input mb-1'
              placeholder={'Search'}
              name={'QTY'}
              onChange={e => setQtyInput(e.target.value)}
              onClick={() => setTerm('QTY')}
            />
          </form>
        </div>
        <div
          style={{ minWidth: '200px', maxWidth: '200' }}
          className='justify-content-end'
        >
          <span className='ps-2'>
            Group Total{' '}
            {psContainer === 'RFT'
              ? ps_unit_type === 'FEET'
                ? '(ft)'
                : '(m)'
              : ''}
          </span>
          <form>
            <input
              type='search'
              className='table-search-input mb-1'
              placeholder={'Search'}
              name={'TOTAL'}
              onChange={e => setTotalInput(e.target.value)}
              onClick={() => setTerm('TOTAL')}
            />
          </form>
        </div>
        <div
          style={{ minWidth: '100px', maxWidth: '100px' }}
          className='justify-content-end'
        >
          <span className='ps-2'>Total</span>
          <form>
            <input
              type='search'
              className='table-search-input mb-1'
              placeholder={'Search'}
              name={'_TOTAL'}
              onChange={e => setTotalInput(e.target.value)}
              onClick={() => setTerm('TOTAL')}
            />
          </form>
        </div>
      </div>
      <PsGroupSummaryLine
        filterSummery={filterSummery}
        psContainer={psContainer}
      />
    </Fragment>
  );
};

export const getGroupDesc = (ps, group) => {
  switch (group) {
    case 'ORDER':
      return ps.order_ref_code;
    case 'PARENT_ORDER':
      return ps.parent_order_ref_code;
    case 'EXT_REFERENCE':
      return ps.order_ext_ref_code;
    case 'SECONDARY_CUSTOMER':
      return ps.order_secondary_customer;
    case 'CUSTOMER':
      return ps.entity_name;
    case 'POD':
      return ps.pod_name;
    case 'ITEM_CODE':
      return ps.item_code;
    case 'MATERIAL':
      return ps.material;
    case 'EDGE':
      return ps.edge;
    default:
      return '';
  }
};

const ProductionSummaryChart = ({ showChart, summaries }) => {
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const valTotal = summaries.map(val => val.qty).reduce((a, b) => a + b, 0);
    setTotal(valTotal);
  }, [summaries]);
  return (
    <div>
      <div className='d-flex justify-content-end'>
        <div
          onClick={() => {
            dispatch(setPsShowChart(showChart !== true));
          }}
        >
          {showChart === true ? (
            <ChevronUpIcon fill={'#006EED'} />
          ) : (
            <ChevronDownIcon fill={'#006EED'} />
          )}
        </div>
      </div>
      {showChart === true && (
        <Fragment>
          <div className='d-flex'>
            <ResponsiveContainer width='100%' minHeight='300px'>
              <BarChart
                width={500}
                height={300}
                data={summaries}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='desc' />
                <YAxis />
                <Tooltip />
                <Bar dataKey='qty' stackId='a' fill='#8884d8'>
                  <LabelList dataKey='qty' position='top' />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div
            className='d-flex justify-content-center'
            style={{ color: '#8884d8', fontSize: '24px' }}
          >
            TOTAL {total}
          </div>
        </Fragment>
      )}
    </div>
  );
};
export const ProductionSummaryContainer = ({
  group,
  group2,
  psSummaries,
  showChart,
  operationCode,
  onDetail,
  consolidationTypes,
  itemType,
  psContainer,
}) => {
  const [graphSummary, setGraphSummary] = useState([]);
  const [groupSummary, setGroupSummary] = useState([]);
  useEffect(() => {
    const descMap = {};
    const groupSummaryMap = {};

    psSummaries
      .filter(v => {
        if (psContainer === 'RFT') {
          return !v.edge?.includes('NO_EDGE');
        }
        return true;
      })
      .forEach(val => {
        if (!descMap[`${val.desc}`]) {
          descMap[`${val.desc}`] = {
            desc: val.desc,
            qty: 0,
          };
        }
        descMap[`${val.desc}`].qty += val.qty;
        if (!groupSummaryMap[`${val.period}`]) {
          groupSummaryMap[`${val.period}`] = {
            key: val.period,
            label: val.desc,
            qty: 0,
            list: {},
          };
        }

        const periodSummary = groupSummaryMap[`${val.period}`];
        periodSummary.qty += val.qty;
        const _itemType = itemType === 'ALL' ? itemType : val.item_code;
        if (!periodSummary.list[`${_itemType}`]) {
          periodSummary.list[`${_itemType}`] = {
            key: _itemType,
            qty: 0,
            list: {},
            edge: val.edge,
          };
        }
        const _group = group ? getGroupDesc(val, group) : 'NONE';
        const _group2 = group2 ? getGroupDesc(val, group2) : 'NONE';
        const itemTypeSummary = periodSummary.list[`${_itemType}`];
        itemTypeSummary.qty += val.qty;
        if (!itemTypeSummary.list[`${_group}`]) {
          itemTypeSummary.list[`${_group}`] = {
            key: _group,
            qty: 0,
            list: {},
          };
        }
        const groupSummary = itemTypeSummary.list[`${_group}`];
        groupSummary.qty += val.qty;
        if (!groupSummary.list[`${_group2}`]) {
          groupSummary.list[`${_group2}`] = {
            key: _group2,
            qty: 0,
            list: {},
          };
        }
        const groupSummary2 = groupSummary.list[`${_group2}`];
        groupSummary2.qty += val.qty;
      });
    const gSum = Object.values(descMap);
    setGraphSummary(gSum);
    const gSummary = Object.keys(groupSummaryMap).map(periodKey => {
      const periodSummary = groupSummaryMap[`${periodKey}`];
      const periodList = Object.keys(periodSummary.list).map(itemTypeKey => {
        const itemTypeSummary = periodSummary.list[`${itemTypeKey}`];
        const itemSummaryList = Object.keys(itemTypeSummary.list).map(
          groupSummaryKey => {
            const groupSummary = itemTypeSummary.list[`${groupSummaryKey}`];
            const groupSummaryList = Object.keys(groupSummary.list).map(
              group2SummaryKey => {
                const group2Summary = groupSummary.list[`${group2SummaryKey}`];
                return { ...group2Summary, list: [] };
              },
            );
            groupSummaryList.sort((a, b) => {
              return a.key.localeCompare(b.key);
            });
            return { ...groupSummary, list: groupSummaryList };
          },
        );
        itemSummaryList.sort((a, b) => {
          return a.key.localeCompare(b.key);
        });
        return { ...itemTypeSummary, list: itemSummaryList };
      });
      periodList.sort((a, b) => {
        return a.key.localeCompare(b.key);
      });
      return { ...periodSummary, list: periodList };
    });
    gSummary.sort((a, b) => {
      return moment(b.key).isAfter(moment(a.key));
    });

    setGroupSummary(gSummary);
  }, [
    psSummaries,
    setGraphSummary,
    itemType,
    group,
    group2,
    setGroupSummary,
    psContainer,
  ]);
  return (
    <Fragment>
      <div
        className=''
        style={{
          paddingTop: '0px !important',
          width: '100%',
          position: 'relative',
          height: 'calc(100vh - 200px)',
          overflow: 'scroll',
        }}
      >
        <ProductionSummaryChart
          summaries={graphSummary}
          showChart={showChart}
        />
        <div
          className='font-weight-bold pt-3 table'
          style={{ minWidth: '1000px', height: '100%' }}
        >
          <PsSummaryTable
            group={group}
            group2={group2}
            itemType={itemType}
            showChart={showChart}
            groupSummary={groupSummary}
            consolidationTypes={consolidationTypes}
            onDetail={onDetail}
            psContainer={psContainer}
          />
        </div>
      </div>
    </Fragment>
  );
};

import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import Select from '../packing/common/select';
import PropTypes from 'prop-types';
import {
  getEntities,
  getOrderByTerm,
  getOrderExternalCodes,
  getOrderSecondaryCustomers,
} from '../../services/order';
import { getWorkOrders } from '../../services/cutsmart';
import {
  searchShipmentCollaborators,
  searchShipments,
} from '../../services/shipment';

export const CTX_TYPES = [
  {
    label: 'Order',
    value: 'ORDER',
  },
  {
    label: 'Parent Order',
    value: 'PARENT_ORDER',
  },
  {
    label: 'Customer',
    value: 'CUSTOMER',
  },
  {
    label: 'External Reference',
    value: 'ORDER_EXTERNAL_REFERENCE',
  },
  {
    label: 'Secondary Customer',
    value: 'SECONDARY_CUSTOMER',
  },
  {
    label: 'CutSmart Work Order',
    value: 'WO',
  },
  {
    label: 'Work Order',
    value: 'WORK_ORDER',
  },
  {
    label: 'Shipment',
    value: 'SHIPMENT',
  },
  {
    label: 'MATERIALS',
    value: 'MAT',
  },
  {
    label: 'Shared Shipment',
    value: 'SHIPMENT_COLLABORATOR',
  },
];
/* eslint-disable sonarjs/cognitive-complexity */
export const SelectContext = memo(
  ({
    types,
    onCtxTypeSelect,
    onCtxValueSelect,
    getOptions,
    ctxType,
    ctxValue,
    showBusy,
    additionalFilters,
  }) => {
    const [ctxTypes, setCtxTypes] = useState(
      CTX_TYPES.filter(val => !types || types.indexOf(val.value) >= 0),
    );

    useEffect(() => {
      if (!types || types.length === 0) {
        setCtxTypes(CTX_TYPES);
        return;
      }
      setCtxTypes(
        CTX_TYPES.filter(val => !types || types.indexOf(val.value) >= 0),
      );
    }, [types, setCtxTypes]);

    const getOrdersForSelect = useCallback(
      async term => {
        const orderResp = await getOrderByTerm(
          term,
          false,
          null,
          additionalFilters || {},
        );
        if (orderResp.status !== 200) {
          return [];
        }
        return orderResp.payload.map(val => {
          return {
            key: val.uuid,
            label: val.ref_code,
            code: val.code,
            ref_code: val.ref_code,
            branch_id: val.branch_id,
            org_id: val.org_id,
            parents: val.parents,
          };
        });
      },
      [additionalFilters],
    );
    const getCustomerForSelect = useCallback(async term => {
      const entitiesResult = await getEntities(
        {
          term: term,
          deleted: [false],
        },
        0,
        10,
      );
      if (entitiesResult.status !== 200) {
        return [];
      }
      return entitiesResult.payload.map(val => {
        return {
          key: val.uuid,
          label: val.name,
          name: val.name,
          description: val.description,
          org_id: val.org_id,
        };
      });
    }, []);
    const getOrderExternalRefForSelect = useCallback(async term => {
      const extCodesResp = await getOrderExternalCodes(term, 0, 5);
      if (extCodesResp.status !== 200) {
        return [];
      }
      return extCodesResp.payload.map(val => {
        return {
          key: val.ext_code,
          label: val.ext_code,
          ext_code: val.ext_code,
          branch_id: val.branch_id,
          org_id: val.org_id,
        };
      });
    }, []);
    const getOrderSecondaryCustomerForSelect = useCallback(async term => {
      const extCodesResp = await getOrderSecondaryCustomers(term, 0, 5);
      if (extCodesResp.status !== 200) {
        return [];
      }
      return extCodesResp.payload.map(val => {
        return {
          key: val.secondary_customer,
          label: val.secondary_customer,
          secondary_customer: val.secondary_customer,
          branch_id: val.branch_id,
          org_id: val.org_id,
        };
      });
    }, []);
    const getWorkOrderMappedOrder = useCallback(async term => {
      const orderResp = await getOrderByTerm(term, false, 'WO');
      if (orderResp.status !== 200) {
        return [];
      }
      return orderResp.payload.map(val => {
        return {
          key: val.uuid,
          label: val.ref_code,
          code: val.code,
          ref_code: val.ref_code,
          branch_id: val.branch_id,
          org_id: val.org_id,
          parents: val.parents,
        };
      });
    }, []);
    const getWorkOrderForSelect = useCallback(async term => {
      const woListResp = await getWorkOrders({
        page: 0,
        size: 5,
        req_payload: {
          wo_status: ['RELEASED'],
          deleted: [false],
          term: term,
        },
      });
      if (woListResp.status !== 200) {
        return [];
      }
      return woListResp.payload.map(val => {
        return {
          key: val.uuid,
          label: val.wo_name,
          org_id: val.org_id,
        };
      });
    }, []);

    const getShipmentForSelect = useCallback(async term => {
      const shipmentListResp = await searchShipments(term, false, 0, 8);
      if (shipmentListResp.status !== 200) {
        return [];
      }
      return shipmentListResp.payload.map(val => {
        return {
          key: val.uuid,
          label: val.code,
          org_id: val.org_id,
        };
      });
    }, []);
    const getCollaboratorShipmentForSelect = useCallback(async term => {
      const shipmentListResp = await searchShipmentCollaborators(term, 0, 20);
      if (
        shipmentListResp.status !== 200 ||
        shipmentListResp.payload.length === 0
      ) {
        return [];
      }
      return shipmentListResp.payload.map(val => {
        return {
          key: val.shipment.uuid,
          label: val.shipment.code,
          org_id: val.org_id,
          branch_id: val.shipment.branch_id,
          collaborator_id: val.uuid,
        };
      });
    }, []);
    const getSelectOptions = useCallback(
      term => {
        return new Promise(async (resolve, reject) => {
          if (!ctxType) {
            resolve([]);
            return;
          }
          try {
            if (ctxType.value === 'ORDER' || ctxType.value === 'PARENT_ORDER') {
              const result = await getOrdersForSelect(term);
              resolve(result);
              return;
            } else if (ctxType.value === 'CUSTOMER') {
              const result = await getCustomerForSelect(term);
              resolve(result);
              return;
            } else if (ctxType.value === 'ORDER_EXTERNAL_REFERENCE') {
              const result = await getOrderExternalRefForSelect(term);
              resolve(result);
              return;
            } else if (ctxType.value === 'SECONDARY_CUSTOMER') {
              const result = await getOrderSecondaryCustomerForSelect(term);
              resolve(result);
              return;
            } else if (ctxType.value === 'WO') {
              const result = getWorkOrderForSelect(term);
              resolve(result);
              return;
            } else if (ctxType.value === 'WORK_ORDER') {
              const result = getWorkOrderMappedOrder(term);
              resolve(result);
              return;
            } else if (ctxType.value === 'SHIPMENT') {
              const result = getShipmentForSelect(term);
              resolve(result);
              return;
            } else if (ctxType.value === 'SHIPMENT_COLLABORATOR') {
              const result = getCollaboratorShipmentForSelect(term);
              resolve(result);
              return;
            } else if (getOptions) {
              const result = await getOptions(term);
              resolve(result || []);
              return;
            }
            resolve([]);
          } catch (err) {
            reject([]);
          }
        });
      },
      [
        ctxType,
        getOrdersForSelect,
        getCustomerForSelect,
        getOrderExternalRefForSelect,
        getOrderSecondaryCustomerForSelect,
        getWorkOrderForSelect,
        getWorkOrderMappedOrder,
        getShipmentForSelect,
        getCollaboratorShipmentForSelect,
        getOptions,
      ],
    );
    return (
      <Fragment>
        <Select
          label={`Select ${ctxType ? ctxType.label : ''} ..`}
          getOptions={getSelectOptions}
          labelField='label'
          valueField='key'
          onSelect={onCtxValueSelect}
          selected={ctxValue}
          className='order-id fs-36'
          search
          showBusy={showBusy}
        />
        <Select
          label='Select Category'
          getOptions={() => Promise.resolve(ctxTypes)}
          labelField='label'
          valueField='value'
          onSelect={onCtxTypeSelect}
          selected={ctxType}
          showBusy={false}
          className='order-select-type label fs-14 pl-4p'
        />
      </Fragment>
    );
  },
);
/* eslint-enable sonarjs/cognitive-complexity */
SelectContext.propTypes = {
  types: PropTypes.array,
  ctxTypeList: PropTypes.array,
  onCtxTypeSelect: PropTypes.func.isRequired,
  onCtxValueSelect: PropTypes.func.isRequired,
  ctxType: PropTypes.object,
  ctxValue: PropTypes.object,
  showBusy: PropTypes.bool,
};

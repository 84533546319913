// icon:info-circled | Radix Icons https://icons.radix-ui.com/ | WorkOS
import * as React from 'react';

export const IconInfoCircled = props => {
  return (
    <svg fill='none' viewBox='0 0 15 15' height='1em' width='1em' {...props}>
      <path
        fill='blue'
        fillRule='evenodd'
        d='M7.5.877a6.623 6.623 0 100 13.246A6.623 6.623 0 007.5.877zM1.827 7.5a5.673 5.673 0 1111.346 0 5.673 5.673 0 01-11.346 0zm6.423-3a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM6 6h1.5a.5.5 0 01.5.5V10h1v1H6v-1h1V7H6V6z'
        clipRule='evenodd'
      />
    </svg>
  );
};
export const IconInfoCircledWhite = props => {
  return (
    <svg
      fill='none'
      viewBox='0 0 15 15'
      height='1.3em'
      width='1.3em'
      {...props}
    >
      <circle cx='7.5' cy='7.5' r='6' fill='white' />
      <path
        fill='black'
        fillRule='evenodd'
        d='M7.5.877a6.623 6.623 0 100 13.246A6.623 6.623 0 007.5.877zM1.827 7.5a5.673 5.673 0 1111.346 0 5.673 5.673 0 01-11.346 0zm6.423-3a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM6 6h1.5a.5.5 0 01.5.5V10h1v1H6v-1h1V7H6V6z'
        clipRule='evenodd'
      />
    </svg>
  );
};

/* eslint-disable sonarjs/cognitive-complexity */
import { getState } from '../../store';
import { cloneDeep, isEqual } from 'lodash';
import {
  addBox,
  addItem,
  removeBoxItem,
  removeBoxItemHistory,
  setItems,
  syncBoxItem,
} from '../../store/packing/itemslist/actions';
import {
  postScanRecord,
  getBoxItemByUuid,
  createCurrentSelectionPredefinedBoxNoBoxes,
  toastSuccess,
} from './packingHelper';
import {
  DEFAULT_ITEM_FEATURES,
  getItemsWithFilter,
  getOperationChecklistWithFilter,
  recalculateBoxStatus,
} from '../../services/order';
import {
  createOrAddNewBox,
  getAllBoxItemsWithFilter,
  getBoxesWithFilter,
  getBoxItemsWithFilter,
} from '../../services/packing';

import { ResponseCodes } from '@naadi/framework';
import moment from 'moment';
import {
  CHANGE_SELECTION,
  setBoxItemSyncDate,
  setBoxSyncDate,
  setItemSyncDate,
  setPackingOperationSyncDate,
} from '../../store/packing/selection/actions';
import { toastResponseError } from '../common/helper';
import { actions } from '@naadi/framework';

const BOX_ITEM_SYNCED = 'Box Item Synced';
const SELECTION_HAS_CHANGED = 'Selection Has Changed';
const SELECTION_CHANGE_ERROR_MAP = {
  type: 'error',
  message: SELECTION_HAS_CHANGED,
};
/* eslint-disable sonarjs/no-duplicated-branches */
export const checkIfSelectionChanged = _selection => {
  const { selection } = getState();
  let returnVal = false;
  if (!selection || !_selection) {
    returnVal = true;
  } else {
    const _category = _selection.category;
    const category = selection.category;
    if (!selection.selection || !_selection.selection) {
      returnVal = true;
    } else if (!category || !_category || !category.value || !_category.value) {
      returnVal = true;
    } else if (!selection.selection.uuid || !_selection.selection.uuid) {
      returnVal = true;
    } else if (category.value !== _category.value) {
      returnVal = true;
    } else if (selection.selection.uuid !== _selection.selection.uuid) {
      returnVal = true;
    } else if (getState().busy.includes(CHANGE_SELECTION)) {
      returnVal = true;
    }
  }

  return returnVal;
};
/* eslint-enable sonarjs/no-duplicated-branches */

export const syncPackingBoxById = async (dispatch, box) => {
  const { itemslist } = getState();
  const { selection } = getState();
  const itemsList = itemslist;
  const fetchBoxes = itemsList.fetchBoxes;
  const status = {
    errors: 0,
    errorlist: [],
    i: 0,
  };
  if (checkIfSelectionChanged(selection)) {
    status.errors++;
    status.errorlist.push(SELECTION_HAS_CHANGED);
    return status;
  }
  const _box = cloneDeep(fetchBoxes.filter(val => val.uuid === box.uuid)[0]);
  if (!_box) {
    status.errors++;
    status.errorlist.push('The box to validate does not exist');
    return status;
  }
  const boxResp = await getBoxesWithFilter({
    req_payload: { uuid: [box.uuid] },
  });
  if (boxResp.status !== 200 || boxResp.payload.length === 0) {
    status.errors++;
    status.errorlist.push('Unable to fetch the Box detail');
    return status;
  }
  const fetchedBox = boxResp.payload[0];
  if (
    fetchedBox.deleted !== _box.deleted ||
    fetchedBox.box_index !== _box.box_index ||
    fetchedBox.qty !== _box.qty
  ) {
    _box.deleted = fetchedBox.deleted;
    _box.box_index = fetchedBox.box_index;
    _box.qty = fetchedBox.qty;
    _box.synced = true;
    _box.rev = fetchedBox.rev;
    _box.updated_on = fetchedBox.updated_on;
    dispatch(syncBoxItem(_box));
  }
  if (fetchedBox.deleted === true) {
    status.errors++;
    status.errorlist.push('box has been deleted. please refresh');
    return status;
  }
  const boxItemResp = await getAllBoxItemsWithFilter({
    req_payload: { box_id: [box.uuid], deleted: [false] },
  });
  if (boxItemResp.status !== 200) {
    status.errors++;
    status.errorlist.push('Unable to fetch the Box item detail');
    return status;
  }
  const boxItemMap = {};
  const boxItems = boxItemResp.payload;
  boxItems.forEach(val => {
    boxItemMap[`${val.uuid}`] = val;
  });
  const _boxItems = itemsList.fetchBoxItems.filter(
    val => val.box_id === box.uuid || (val.box && val.box.uuid === box.uuid),
  );
  const currentBoxMap = {};
  _boxItems.forEach(val => {
    if (!val.deleted) {
      currentBoxMap[`${val.uuid}`] = val;
    }
    if (!boxItemMap[`${val.uuid}`]) {
      const newVal = cloneDeep(val);
      newVal.deleted = true;
      newVal.synced = true;
      dispatch(syncBoxItem(newVal));
      return;
    }
    const _boxItem = boxItemMap[`${val.uuid}`];
    if (
      _boxItem.deleted !== val.deleted ||
      _boxItem.box_index !== val.box_index ||
      _boxItem.qty !== val.qty
    ) {
      const newVal = cloneDeep(val);
      newVal.deleted = _boxItem.deleted;
      newVal.box_index = _boxItem.box_index;
      newVal.qty = _boxItem.qty;
      newVal.rev = _boxItem.rev;
      newVal.updated_on = _boxItem.updated_on;
      newVal.synced = true;
      dispatch(syncBoxItem(newVal));
    }
  });
  boxItems.forEach(val => {
    if (!currentBoxMap[`${val.uuid}`]) {
      val.synced = true;
      dispatch(syncBoxItem(val));
    }
  });
  const filteredBoxItems = _boxItems.filter(val => !val.deleted);
  if (boxItems.length !== filteredBoxItems.length) {
    status.errors++;
    status.errorlist.push(
      'The box items have changed in the backend. Please verify again and print',
    );
  }

  return status;
};

export const syncPackingBox = async (dispatch, box) => {
  const { itemslist } = getState();
  const { selection } = getState();
  const itemsList = itemslist;
  const fetchBoxes = itemsList.fetchBoxes;
  const dropBoxes = [];
  const status = {
    errors: 0,
    errorlist: [],
    i: 0,
  };
  if (checkIfSelectionChanged(selection)) {
    status.errors++;
    status.errorlist.push(SELECTION_HAS_CHANGED);
    return status;
  }
  for (status.i = 0; status.i < fetchBoxes.length; status.i++) {
    const val = fetchBoxes[parseInt(status.i)];
    if (val.synced === true) continue;
    if (box && box.uuid !== val.uuid) continue;
    try {
      if (box && box.deleted === true) {
        const checkIfPendingDelete = itemsList.fetchBoxItems.find(
          val => val.box.uuid === box.uuid && val.deleted === false,
        );
        if (checkIfPendingDelete) {
          console.log(
            'Wait to Sync the Box Delete as Item is Yet to be Synced',
          );
          continue;
        }
      }
      if (val && val.deleted === true) {
        const checkIfPendingSync = itemsList.fetchBoxItems.filter(
          _val => _val.box.uuid === val.uuid && _val.synced === false,
        );
        if (checkIfPendingSync.length > 0) {
          console.log('deleted box conttains items');
          console.log(checkIfPendingSync);
          for (let i = 0; i < checkIfPendingSync.length; i++) {
            const _val = checkIfPendingSync[`${i}`];
            await syncPackingBoxItem(_val, dispatch);
          }
        }
      }
      const boxResp = await createOrAddNewBox(val);
      if (boxResp.status === 200) {
        const updatedBox = boxResp.payload;
        updatedBox.synced = true;
        dispatch(addBox(updatedBox));
      } else if (
        boxResp.status === ResponseCodes.RECORD_EXISTS_EXCEPTION &&
        val.deleted === true
      ) {
        const boxItemFilter = {
          req_payload: {
            box_id: [val.uuid],
            deleted: [false],
          },
        };
        const boxItemsResult = await getAllBoxItemsWithFilter(boxItemFilter);
        if (boxItemsResult.status === ResponseCodes.SUCCESS) {
          boxItemsResult.payload.forEach(boxItem => {
            const _boxItem = itemsList.fetchBoxItems.find(
              __val => __val.uuid === boxItem.uuid,
            );
            if (!_boxItem) {
              boxItem.synced = false;
              dispatch(syncBoxItem(boxItem));
            } else {
              const _existingBoxItem = cloneDeep(_boxItem);
              _existingBoxItem.synced = false;
              dispatch(syncBoxItem(_existingBoxItem));
            }
          });
        } else {
          status.errors++;
          status.errorlist.push(boxResp);
        }
      } else if (
        boxResp.status === ResponseCodes.RECORD_EXISTS_EXCEPTION &&
        val.deleted === false
      ) {
        const boxFilter = {
          req_payload: {
            uuid: [val.uuid],
            deleted: [false],
          },
        };
        const boxResult = await getBoxesWithFilter(boxFilter);
        if (
          boxResult.status === ResponseCodes.SUCCESS &&
          boxResult.payload.length > 0
        ) {
          const existingBox = boxResult.payload[0];
          if (
            existingBox.packing_context === 'ORDER' &&
            existingBox.packing_context_ref !== val.packing_context_ref
          ) {
            dropBoxes.push(val);
          } else {
            status.errors++;
            status.errorlist.push(boxResp);
          }
        } else {
          status.errors++;
          status.errorlist.push(boxResp);
        }
      } else {
        status.errors++;
        status.errorlist.push(boxResp);
        console.log(boxResp);
      }
      //console.log(boxResp);
    } catch (err) {
      status.errors++;
      status.errorlist.push(err);
      console.log(err);
    }
  }
  if (dropBoxes.length > 0) {
    console.log('Boxes from Different Order Added here. Need to Do something');
    console.log(dropBoxes);
  }
  return status;
};
const SKIP_SYNC_MESSAGE = 'Skip Sync of Items as Box is not Synced';
export const syncPackingBoxItemHistory = async (boxItem, syncBox, dispatch) => {
  const { itemslist, selection } = getState();
  const itemsList = itemslist;
  let box = itemsList.fetchBoxes.find(_box => _box.uuid === boxItem.box.uuid);
  if (box === undefined) {
    return {
      type: 'error',
      message: 'Box Not Found',
    };
  }
  if (box.synced === false && syncBox === true) {
    await syncPackingBox(dispatch, box);
    box = getState().itemslist.fetchBoxes.find(
      _box => _box.uuid === boxItem.box.uuid,
    );
  }
  if (box.synced === false) {
    console.log(SKIP_SYNC_MESSAGE);
    return {
      type: 'error',
      message: SKIP_SYNC_MESSAGE,
    };
  }
  if (boxItem.synced === true && boxItem.history.length === 0) {
    //console.log('Skip Sync of Items as BoxItem is not Synced');
    return {
      type: 'success',
      message: 'Item is Already in Sync',
      updated: false,
    };
  }

  let boxItemUpdated = false;
  for (let i = 0; i < boxItem.history.length; i++) {
    const history = cloneDeep(boxItem.history[`${i}`]);
    history.acknowledged = true;
    history.scan_status = 'SUCCESS';
    const scanRecordResp = await postScanRecord(
      history,
      boxItem,
      box,
      selection.category.value,
      selection.selection.uuid,
    );
    if (scanRecordResp.status === 200) {
      dispatch(
        removeBoxItemHistory({
          uuid: boxItem.uuid,
          scan_id: history.scan_id,
        }),
      );
      boxItemUpdated = true;
    } else {
      return {
        type: 'error',
        message: 'Failed to Post the Scan History',
      };
    }
  }
  return {
    type: 'success',
    message: 'Box Item History Updated',
    updated: boxItemUpdated,
  };
};
export const syncPackingBoxItem = async (boxItem, dispatch) => {
  const { itemslist, selection } = getState();
  const itemsList = itemslist;
  const box = itemsList.fetchBoxes.find(_box => _box.uuid === boxItem.box.uuid);
  if (box === undefined) {
    const _boxItems = await getBoxItemByUuid(boxItem);
    if (_boxItems != null && _boxItems.length > 0) {
      const _boxItem = _boxItems[0];
      if (boxItem.deleted === true && _boxItem.deleted === true) {
        const boxItemForUpdate = cloneDeep(boxItem);
        boxItemForUpdate.synced = true;
        if (checkIfSelectionChanged(selection)) {
          return SELECTION_CHANGE_ERROR_MAP;
        }
        dispatch(syncBoxItem(boxItemForUpdate));
        return {
          type: 'success',
          message: BOX_ITEM_SYNCED,
        };
      }
    }
    return {
      type: 'error',
      message: 'Box Not Found',
    };
  }
  if (box.synced === false) {
    if (box.deleted === true && boxItem.deleted === false) {
      console.log('Skip Sync of Items as Box is not Synced');
      console.log(box);
      return {
        type: 'error',
        message: 'Box Sync is Pending',
      };
    } else {
      console.log('Box and Item Deleted, sync');
    }
  }
  if (boxItem.synced === true && boxItem.history.length === 0) {
    //console.log('Skip Sync of Items as BoxItem is in Synced');
    return {
      type: 'success',
      message: BOX_ITEM_SYNCED,
    };
  }
  await syncPackingBoxItemHistory(boxItem, false, dispatch);

  const _updatedBoxItem = getState().itemslist.fetchBoxItems.find(
    val => val.uuid === boxItem.uuid,
  );
  if (!_updatedBoxItem) {
    return {
      type: 'error',
      message: 'Box Item Not Found',
    };
  }

  if (!_updatedBoxItem.synced) {
    //offline sync has been disabled. Commenting this code
    if (checkIfSelectionChanged(selection)) {
      return SELECTION_CHANGE_ERROR_MAP;
    }
    dispatch(removeBoxItem(boxItem.uuid));
    return {
      type: 'success',
      message: BOX_ITEM_SYNCED,
    };
  }
  return {
    type: 'success',
    message: 'Box Item Synced',
  };
};
export const syncPackingBoxItems = async dispatch => {
  const { itemslist } = getState();
  const itemsList = itemslist;
  const fetchBoxItems = itemsList.fetchBoxItems;
  const syncStatus = {
    errors: 0,
    errorlist: [],
  };
  for (let i = 0; i < fetchBoxItems.length; i++) {
    const boxItem = fetchBoxItems[parseInt(i)];
    try {
      const itemSync = await syncPackingBoxItem(boxItem, dispatch);
      if (itemSync.type === 'error') {
        await syncPackingBoxItem(boxItem, dispatch);
        syncStatus.errors++;
        syncStatus.errorlist.push(itemSync.message);
      }
    } catch (err) {
      console.log(err);
      syncStatus.errors++;
      syncStatus.errorlist.push(err);
    }
  }
  return syncStatus;
};
export const syncOfflineOrderBoxItemUpdates = async dispatch => {
  console.log('Begin syncOfflineOrderBoxeItemUpdates');
  const { selection, itemslist } = getState();
  if (!selection.selection || !selection.selection.uuid) {
    return;
  }
  const boxItemFilter = {
    page: 0,
    size: 100,
    req_payload: {
      org_id: selection.selection.org_id,
      modified_after: new Date(0),
      packing_context: ['ORDER'],
      packing_context_ref: [selection.selection.uuid],
    },
    sort: [
      {
        column: 'updated_on',
        asc: true,
      },
    ],
  };
  if (selection.boxSyncDate && selection.boxSyncDate !== null) {
    boxItemFilter.req_payload.modified_after = selection.boxItemSyncDate;
  }
  const modifiedBoxItemsResp = await getBoxItemsWithFilter(boxItemFilter);
  if (checkIfSelectionChanged(selection)) {
    return;
  }
  if (
    modifiedBoxItemsResp &&
    modifiedBoxItemsResp.status === ResponseCodes.SUCCESS
  ) {
    if (modifiedBoxItemsResp.payload.length === 0) return;
    const existingBoxItemMap = {};
    itemslist.fetchBoxItems.forEach(val => {
      existingBoxItemMap[val.uuid + ''] = val;
    });
    let maxSyncTime = moment(boxItemFilter.req_payload.modified_after);
    modifiedBoxItemsResp.payload.forEach(val => {
      if (moment(val.updated_on).isAfter(maxSyncTime)) {
        maxSyncTime = moment(val.updated_on);
      }

      const existingBoxItem = existingBoxItemMap[val.uuid + ''];
      if (!existingBoxItem && val.deleted === false) {
        val.synced = true;
        dispatch(syncBoxItem(val));
        return;
      } else if (!existingBoxItem && val.deleted === true) {
        return;
      }
      if (existingBoxItem.rev < val.rev) {
        const _existingBoxItem = cloneDeep(existingBoxItem);
        _existingBoxItem.synced = false;
        _existingBoxItem.box_item_id = val.box_item_id;
        _existingBoxItem.box = val.box;
        _existingBoxItem.deleted = val.deleted;
        if (checkIfSelectionChanged(selection)) {
          return;
        }
        dispatch(syncBoxItem(_existingBoxItem));
      }
    });
    if (checkIfSelectionChanged(selection)) {
      return;
    }
    dispatch(setBoxItemSyncDate(maxSyncTime.toDate()));
  }
};
export const syncOfflineOrderBoxesUpdates = async dispatch => {
  console.log('Begin syncOfflineOrderBoxesUpdates');
  const { selection, itemslist } = getState();
  if (!selection.selection || !selection.selection.uuid) {
    return;
  }
  if (getState().busy.includes(CHANGE_SELECTION)) {
    return;
  }
  const boxFilter = {
    page: 0,
    size: 100,
    req_payload: {
      org_id: selection.selection.org_id,
      modified_after: new Date(0),
      packing_context: ['ORDER'],
      packing_context_ref: [selection.selection.uuid],
    },
    sort: [
      {
        column: 'updated_on',
        asc: true,
      },
    ],
  };
  if (selection.boxSyncDate && selection.boxSyncDate !== null) {
    boxFilter.req_payload.modified_after = selection.boxSyncDate;
  }
  const modifiedBoxesResp = await getBoxesWithFilter(boxFilter);
  if (checkIfSelectionChanged(selection)) {
    return;
  }
  if (modifiedBoxesResp && modifiedBoxesResp.status === ResponseCodes.SUCCESS) {
    const existingBoxesMap = {};
    itemslist.fetchBoxes.forEach(val => {
      existingBoxesMap[val.uuid + ''] = val;
    });
    let maxSyncTime = moment(boxFilter.req_payload.modified_after);
    modifiedBoxesResp.payload.forEach(val => {
      if (moment(val.updated_on).isAfter(maxSyncTime)) {
        maxSyncTime = moment(val.updated_on);
      }
      if (checkIfSelectionChanged(selection)) {
        return;
      }
      const existingBox = existingBoxesMap[val.uuid + ''];
      if (!existingBox && val.deleted === false) {
        val.synced = true;
        dispatch(addBox(val));
        return;
      } else if (!existingBox && val.deleted === true) {
        return;
      }
      if (
        existingBox.rev < val.rev ||
        existingBox.box_index !== val.box_index
      ) {
        if (val.deleted === true) {
          //let boxItemFound = false;
          itemslist.fetchBoxItems.forEach(_val => {
            if (_val.deleted === true || _val.box.uuid !== val.uuid) return;
            //Exlpre whether to fetch the value from DB and skip if item is not deleted
            const boxItem = cloneDeep(_val);
            boxItem.synced = true;
            boxItem.deleted = true;
            dispatch(syncBoxItem(boxItem));
          });
        }
        val.synced = false;
        dispatch(addBox(val));
      }
    });
    dispatch(setBoxSyncDate(maxSyncTime.toDate()));
  }
  dispatch(createCurrentSelectionPredefinedBoxNoBoxes());
  //console.log(modifiedBoxesResp);
};
const syncOfflineOrderChecklist = async (dispatch, force, loopCnt) => {
  const { selection, itemslist } = getState();
  const page = loopCnt ? loopCnt : 0;
  if (!selection.selection || !selection.selection.uuid) {
    return;
  }
  const packingOperationSyncDate =
    selection.packingOperationSyncDate || new Date(0);
  const temp = {
    fetch_date: new Date(force ? 0 : packingOperationSyncDate).getTime(),
  };
  const filter = {
    page: page,
    size: 100,
    req_payload: {
      modified_after: new Date(temp.fetch_date),
      order_id: [selection.selection.uuid],
      operation_code: ['packing'],
    },
    sort: [
      {
        column: 'updated_on',
        asc: true,
      },
    ],
  };
  const modifiedChecklistResp = await getOperationChecklistWithFilter(filter);
  if (checkIfSelectionChanged(selection)) {
    return;
  }
  if (
    modifiedChecklistResp.status === ResponseCodes.SUCCESS &&
    modifiedChecklistResp.payload.length > 0
  ) {
    const modifiedChecklist = modifiedChecklistResp.payload;
    const checklistMap = {};
    modifiedChecklist.forEach(val => {
      const keyList = [
        val.order_item.uuid,
        val.operation_code,
        val.dependent_code,
      ];
      const key = keyList.join('::');
      checklistMap[`${key}`] = val;
      const time = moment(val.modified).toDate().getTime();
      if (temp.fetch_date < time) {
        temp.fetch_date = time;
      }
    });
    const items = cloneDeep(itemslist.items);
    items.forEach(item => {
      item.operation_checklist.forEach(checklist => {
        const keyList = [
          item.uuid,
          checklist.operation_code,
          checklist.dependent_code,
        ];
        const key = keyList.join('::');
        if (checklistMap[`${key}`]) {
          const val = checklistMap[`${key}`];
          checklist.modified = val.updated_on;
          checklist.completed = val.completed;
        }
      });
    });
    if (checkIfSelectionChanged(selection)) {
      return;
    }
    dispatch(setItems(items));
    dispatch(setPackingOperationSyncDate(new Date(temp.fetch_date)));
    //console.log(modifiedChecklistResp.payload);
    if (modifiedChecklistResp.total_pages > 1 && page < 10) {
      await syncOfflineOrderChecklist(dispatch, false, page + 1);
    }
  }
};

export const recalculateOrderBoxStatus = async dispatch => {
  const { selection } = getState();
  if (!selection.selection || !selection.selection.uuid) {
    return;
  }
  try {
    dispatch(actions.busy.add('RECALCULATE_BOX_STATUS'));
    const resp = await recalculateBoxStatus(selection.selection.uuid);
    if (resp.status !== 200) {
      toastResponseError(resp, 'Unable to Calculate the Box Summary');
      return;
    }
    toastSuccess('Box Status Recalculated');
  } finally {
    dispatch(actions.busy.remove('RECALCULATE_BOX_STATUS'));
  }
};

const syncOfflineOrderItemUpdates = async (dispatch, loopCnt) => {
  //console.log('Begin syncOfflineOrderItemUpdates');
  const { selection, itemslist } = getState();
  const page = loopCnt ? loopCnt : 0;
  if (!selection.selection || !selection.selection.uuid) {
    return;
  }
  const itemFilter = {
    page: 0,
    size: 200,
    req_payload: {
      org_id: selection.selection.org_id,
      modified_after: new Date(0),
      order_id: [selection.selection.uuid],
      released: [true],
      deleted: [false],
      checklist_stations: ['packing'],
      only_visible_features: true,
      features: DEFAULT_ITEM_FEATURES,
    },
    sort: [
      {
        column: 'updated_on',
        asc: true,
      },
    ],
  };
  if (selection.itemSyncDate && selection.itemSyncDate !== null) {
    itemFilter.req_payload.modified_after = selection.itemSyncDate;
    itemFilter.req_payload.deleted = [];
    itemFilter.req_payload.released = [];
  }
  const modifiedItems = await getItemsWithFilter(itemFilter);
  if (checkIfSelectionChanged(selection)) {
    return;
  }
  if (
    modifiedItems.status === ResponseCodes.SUCCESS &&
    modifiedItems.payload.length > 0
  ) {
    const maxSyncDate = modifiedItems.payload
      .map(val => moment(val.updated_on))
      .reduce(
        (a, b) => (a.isAfter(b) ? a : b),
        moment(itemFilter.req_payload.modified_after),
      )
      .toDate();
    modifiedItems.payload.forEach(val => {
      if (val.operation_checklist) {
        val.operation_checklist.forEach(checklist => {
          checklist.modified = new Date(1);
        });
      }
      if (val.deleted || val.released === false) {
        dispatch(addItem(val));
        return;
      }
      const existingItem = itemslist.items.find(_val => _val.uuid === val.uuid);
      if (!existingItem || !isEqual(val, existingItem)) {
        dispatch(addItem(val));
      }
    });
    if (checkIfSelectionChanged(selection)) {
      return;
    }
    dispatch(setItemSyncDate(maxSyncDate));
    if (modifiedItems.payload.length === itemFilter.size && page < 10) {
      await syncOfflineOrderItemUpdates(dispatch, page + 1);
    }
  }
};

export const syncOfflineOrderUpdates = async (dispatch, force) => {
  await syncOfflineOrderItemUpdates(dispatch, 0);
  await syncOfflineOrderBoxesUpdates(dispatch);
  await syncOfflineOrderBoxItemUpdates(dispatch);
  await syncOfflineOrderChecklist(dispatch, force, 0);
};

import { getAttachmentSignedUrlService } from '../../services/upload';
import { toastMessage } from '../packing/packingHelper';
import { ResponseCodes } from '@naadi/framework';
import FileSaver from 'file-saver';

export const sortChildOrders = order => {
  if (!order || !order.child_orders) return;
  order.child_orders.sort((a, b) => {
    return a.id - b.id;
  });
  order.child_orders.forEach(sortChildOrders);
};

export const downloadOrderAttachment = async attachment_id => {
  const orderSrcAttachmentResp = await getAttachmentSignedUrlService(
    attachment_id,
  );
  if (orderSrcAttachmentResp.status !== ResponseCodes.SUCCESS) {
    toastMessage({
      type: 'error',
      message: 'Unable to Fetch the Attachment From Server',
      autoClose: 5000,
    });
    return;
  }
  const attachment = orderSrcAttachmentResp.payload;
  //console.log(attachment.signed_url);
  FileSaver.saveAs(attachment.signed_url, attachment.file_name);
  //console.log(attachmentSignedResponse);
};
